import client from "../../client";
import { useMutation } from "@tanstack/react-query";
import type {
  GenerateClientUrlMutationRequest,
  GenerateClientUrlMutationResponse,
} from "../types/GenerateClientUrl";
import type { UseMutationOptions } from "@tanstack/react-query";

type GenerateClientUrlClient = typeof client<
  GenerateClientUrlMutationResponse,
  never,
  GenerateClientUrlMutationRequest
>;
type GenerateClientUrl = {
  data: GenerateClientUrlMutationResponse;
  error: never;
  request: GenerateClientUrlMutationRequest;
  pathParams: never;
  queryParams: never;
  headerParams: never;
  response: GenerateClientUrlMutationResponse;
  client: {
    parameters: Partial<Parameters<GenerateClientUrlClient>[0]>;
    return: Awaited<ReturnType<GenerateClientUrlClient>>;
  };
};
/**
 * @description Registers a client with request details
 * @link /vat-management/clients/create
 */
export function useGenerateClientUrl(
  options: {
    mutation?: UseMutationOptions<
      GenerateClientUrl["response"],
      GenerateClientUrl["error"],
      GenerateClientUrl["request"]
    >;
    client?: GenerateClientUrl["client"]["parameters"];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async (data) => {
      const res = await client<
        GenerateClientUrl["data"],
        GenerateClientUrl["error"],
        GenerateClientUrl["request"]
      >({
        method: "post",
        url: `/vat-management/clients/create`,
        data,
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}
