import QRCode from "qrcode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button } from "../../components/button";
import { PageTemplateClient } from "../../components/page-template-client";
import { useAppData } from "../../hooks/use-app-data";
import { pages } from "../../util/pages";

export const DeviceManagementRegister = () => {
  const { state } = useAppData();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [qrCodeUri, setQrCodeUri] = useState("");

  useEffect(() => {
    async function generateQRDataUrl(qrData: string | QRCode.QRCodeSegment[]) {
      const opts: QRCode.QRCodeToDataURLOptions = {
        errorCorrectionLevel: "H",
        width: 350,
        margin: 8,
        color: {
          dark: "#000000",
          light: "#FFFFFF",
        },
      };
      setQrCodeUri(await QRCode.toDataURL(qrData, opts));
      setIsLoading(false);
    }
    if (state.qrData) {
      generateQRDataUrl(state.qrData);
    }
  }, [state.qrData]);

  const navigateToClientManagement = () => {
    navigate(pages.MANAGEMENT_DEVICES);
  };

  return (
    <PageTemplateClient>
      <h3 className={"mb-12"}>
        Please scan the QR-code below with the balietool device.
      </h3>
      {isLoading && <>Loading...</>}
      <img src={qrCodeUri} alt="qrCode" className={"mx-auto"} />
      <Button
        variant={"primary"}
        onPointerDown={navigateToClientManagement}
        className="min-w-min mt-8"
      >
        Done
      </Button>
    </PageTemplateClient>
  );
};
