import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Navigate, Route } from "react-router-dom";
import { App } from "./app";
import { RequireProfile } from "./components/require-profile";
import { AppProvider } from "./context/app-context";
import { DeviceManagement } from "./pages/management/device-management";
import { DeviceManagementRegister } from "./pages/management/device-management-register";
import { UserManagement } from "./pages/management/user-management";
import { VisitorManagement } from "./pages/management/visitor-management";
import "./styles/index.css";
import { msalConfig } from "./util/auth-config";
import { pages } from "./util/pages";
import { SentryRoutes, initSentry } from "./util/sentry";

// Fix to keep application open on hardware back button push on device
window.addEventListener("load", () => window.history.pushState({}, ""));
window.addEventListener("popstate", () => window.history.pushState({}, ""));

const initialMSAL = () => {
  if (typeof msalConfig.auth.clientId === "string") {
    const msalInstance = new PublicClientApplication(
      msalConfig as Configuration
    );
    return msalInstance;
  } else {
    console.error(
      "Msal configuration is incorrect. Please check your MSAL configuration "
    );
    return null;
  }
};
const msalInstance = initialMSAL();

initSentry();

const container = document.getElementById("root");
const root = createRoot(container as Element);

root.render(
  <BrowserRouter>
    {msalInstance ? (
      <MsalProvider instance={msalInstance}>
        <AppProvider>
          <SentryRoutes>
            <Route path={pages.MANAGEMENT}>
              <Route
                index
                element={<Navigate to={pages.MANAGEMENT_VISITORS} replace />}
              />
              <Route
                path={pages.MANAGEMENT_VISITORS}
                element={
                  <RequireProfile>
                    <VisitorManagement />
                  </RequireProfile>
                }
              />
              <Route
                path={pages.MANAGEMENT_USERS}
                element={
                  <RequireProfile>
                    <UserManagement />
                  </RequireProfile>
                }
              />
              <Route path={pages.MANAGEMENT_DEVICES}>
                <Route
                  index
                  element={
                    <RequireProfile>
                      <DeviceManagement />
                    </RequireProfile>
                  }
                />
                <Route
                  path={pages.MANAGEMENT_DEVICES_REGISTER}
                  element={<DeviceManagementRegister />}
                />
              </Route>
            </Route>
            {/* Legacy redirects */}
            <Route
              path={pages.__LEGACY_MANAGEMENT}
              element={<Navigate to={pages.MANAGEMENT} replace />}
            />
            <Route
              path={pages.__LEGACY_MANAGEMENT_USERS}
              element={<Navigate to={pages.MANAGEMENT_USERS} replace />}
            />
            <Route
              path={pages.__LEGACY_MANAGEMENT_DEVICES}
              element={<Navigate to={pages.MANAGEMENT_DEVICES} replace />}
            />
            {/* End legacy redirects */}
            <Route path={"*"} element={<App />} />
          </SentryRoutes>
        </AppProvider>
      </MsalProvider>
    ) : (
      <AppProvider>
        <SentryRoutes>
          <Route path={"*"} element={<App />} />
        </SentryRoutes>
      </AppProvider>
    )}
  </BrowserRouter>
);
