import { useLocation, useNavigate } from "react-router-dom";
import { actions } from "../types/actions";
import { useAppData } from "../hooks/use-app-data";
import { pages } from "../util/pages";
import { Button } from "./button";
import { PageTemplateClient } from "./page-template-client";

export const PageNotFound = () => {
  const navigate = useNavigate();
  const { dispatch } = useAppData();
  const location = useLocation();

  const navigateHome = () => {
    dispatch({
      type: actions.SET_ERROR,
      payload: null,
    });

    if (location.pathname.includes("management")) {
      navigate(pages.MANAGEMENT);
    } else {
      navigate(pages.HOME);
    }
  };

  return (
    <PageTemplateClient>
      <h1 data-testid="page-title">Hmm, we couldn&apos;t find that page 🤔</h1>
      <p className={"my-8"}>
        Looks like this page is missing or an error has occurred.
      </p>
      <div className={"flex justify-center"}>
        <Button
          variant={"primary"}
          onPointerDown={navigateHome}
          data-testid="btn-back-home"
        >
          Back to start
        </Button>
      </div>
    </PageTemplateClient>
  );
};
