import { useNavigate } from "react-router";
import { Button } from "../../components/button";
import { PageTemplateClient } from "../../components/page-template-client";
import { ProgressBar } from "../../components/progress-bar";
import { useAppData } from "../../hooks/use-app-data";
import { ReactComponent as IconVisitorBadge } from "../../svg/io_visitor_badge.svg";
import { pages } from "../../util/pages";

export const CheckedOut = () => {
  const { state } = useAppData();
  const navigate = useNavigate();

  const backToStartHandler = (e: { preventDefault: () => void } | null) => {
    if (e) e.preventDefault();
    navigate(pages.HOME);
  };

  const handleProgressBarCompletion = () => {
    backToStartHandler(null);
  };

  const CheckedOutFooter = () => (
    <Button
      variant={"primary"}
      onPointerDown={backToStartHandler}
      data-testid="btn-back-to-start"
    >
      Back to start
    </Button>
  );

  return (
    <PageTemplateClient>
      <div className="flex flex-col">
        <div className="flex flex-col w-[60vw]">
          <h1 data-testid="page-title" className={"mb-8"}>
            Successful check out
          </h1>
          <h5 className="text-h5">We hope you had a nice visit.</h5>
          <h5 className="text-h5">See you next time!</h5>
        </div>
        <div className="flex flex-col mb-20">
          <div className="flex flex-row items-center mb-12">
            <div className={"w-12"}>
              <IconVisitorBadge />
            </div>
            <h3 className={"ml-8 mt-4"}>Please return your badge</h3>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <p className="mb-[0] font-normal">Name:</p>
              <h5 className="text-h5">
                {state.recognizedVisitor &&
                  state.recognizedVisitor.visitor.name}
              </h5>
            </div>
          </div>
        </div>
        <CheckedOutFooter />
      </div>

      <ProgressBar onFinished={handleProgressBarCompletion} />
    </PageTemplateClient>
  );
};
