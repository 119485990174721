import React from "react";
import { twMerge } from "tailwind-merge";

type InputProps = {
  id: string;
  name: string;
  type: string;
  className?: string;
  list?: string;
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  "data-testid"?: string;
  required?: boolean;
};

export const Input = ({
  id,
  name,
  type,
  className,
  required,
  ...props
}: InputProps) => (
  <input
    id={id}
    name={name}
    type={type}
    required={required}
    placeholder={type}
    className={twMerge(
      "block w-full h-14 text-h6 pb-2 bg-primary-baseGreen bg-clip-padding placeholder-[#2525254D] border-b border-solid border-grey-grey90 transition ease-in-out m-0 focus:border-b-2 focus:outline-none mt-2",
      className
    )}
    autoComplete="off"
    {...props}
  />
);
