import client from "../../client";
import {
  useQuery,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import type {
  RegisterSessionQueryResponse,
  RegisterSessionHeaderParams,
} from "../types/RegisterSession";
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";

type RegisterSessionClient = typeof client<
  RegisterSessionQueryResponse,
  never,
  never
>;
type RegisterSession = {
  data: RegisterSessionQueryResponse;
  error: never;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: RegisterSessionHeaderParams;
  response: RegisterSessionQueryResponse;
  client: {
    parameters: Partial<Parameters<RegisterSessionClient>[0]>;
    return: Awaited<ReturnType<RegisterSessionClient>>;
  };
};
export const registerSessionQueryKey = () =>
  [{ url: "/register-session" }] as const;
export type RegisterSessionQueryKey = ReturnType<
  typeof registerSessionQueryKey
>;
export function registerSessionQueryOptions(
  headers: RegisterSession["headerParams"],
  options: RegisterSession["client"]["parameters"] = {}
) {
  const queryKey = registerSessionQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        RegisterSession["data"],
        RegisterSession["error"]
      >({
        method: "get",
        url: `/register-session`,
        headers: { ...headers, ...options.headers },
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @summary Retrieves (registers) the session
 * @link /register-session
 */
export function useRegisterSession<
  TData = RegisterSession["response"],
  TQueryData = RegisterSession["response"],
  TQueryKey extends QueryKey = RegisterSessionQueryKey
>(
  headers: RegisterSession["headerParams"],
  options: {
    query?: Partial<
      QueryObserverOptions<
        RegisterSession["response"],
        RegisterSession["error"],
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: RegisterSession["client"]["parameters"];
  } = {}
): UseQueryResult<TData, RegisterSession["error"]> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? registerSessionQueryKey();
  const query = useQuery({
    ...(registerSessionQueryOptions(
      headers,
      clientOptions
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">),
  }) as UseQueryResult<TData, RegisterSession["error"]> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
export const registerSessionSuspenseQueryKey = () =>
  [{ url: "/register-session" }] as const;
export type RegisterSessionSuspenseQueryKey = ReturnType<
  typeof registerSessionSuspenseQueryKey
>;
export function registerSessionSuspenseQueryOptions(
  headers: RegisterSession["headerParams"],
  options: RegisterSession["client"]["parameters"] = {}
) {
  const queryKey = registerSessionSuspenseQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        RegisterSession["data"],
        RegisterSession["error"]
      >({
        method: "get",
        url: `/register-session`,
        headers: { ...headers, ...options.headers },
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @summary Retrieves (registers) the session
 * @link /register-session
 */
export function useRegisterSessionSuspense<
  TData = RegisterSession["response"],
  TQueryKey extends QueryKey = RegisterSessionSuspenseQueryKey
>(
  headers: RegisterSession["headerParams"],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        RegisterSession["response"],
        RegisterSession["error"],
        TData,
        TQueryKey
      >
    >;
    client?: RegisterSession["client"]["parameters"];
  } = {}
): UseSuspenseQueryResult<TData, RegisterSession["error"]> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? registerSessionSuspenseQueryKey();
  const query = useSuspenseQuery({
    ...(registerSessionSuspenseQueryOptions(
      headers,
      clientOptions
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">),
  }) as UseSuspenseQueryResult<TData, RegisterSession["error"]> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
