import React from "react";
import { useNavigate } from "react-router";
import { Button } from "../components/button";
import { PageTemplateClient } from "../components/page-template-client";
import { UnorderedList } from "../components/unordered-list";
import { pages } from "../util/pages";

export const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const backToStartHandler = (e: React.PointerEvent) => {
    e.preventDefault();
    navigate(pages.HOME);
  };

  const PrivacyPolicyFooter = () => (
    <Button
      onPointerDown={backToStartHandler}
      // HACK: onPointerDown does not fire on iOS Safari for this button
      // (no idea why), so we use onMouseDown as a workaround.
      onMouseDown={backToStartHandler}
      variant={"link"}
      data-testid="btn-back-to-start"
    >
      Back to start
    </Button>
  );

  return (
    <PageTemplateClient footer={<PrivacyPolicyFooter />}>
      <h1 data-testid="page-title">
        Privacy Statement Visitor Administration Tool
      </h1>
      <p>
        Welcome to iO! Through this way, iO informs you about the personal data
        iO processes in the context of Visitor Administration.
      </p>

      <p>
        <strong>
          Why does iO process your personal data for an appointment?
        </strong>
      </p>
      <p>
        iO processes your personal data to schedule your appointment and ensure
        a smooth visit to our campus.
      </p>
      <p>
        When you arrive in the building, you must sign in and register at
        iO&apos;s reception desk. When you leave, you must sign out. This is
        necessary so that iO knows who you are and with whom you have an
        appointment. Also, iO and any emergency services need to know who is in
        the building at any time in case of unexpected emergencies. Finally, iO
        processes your personal data for the purpose of watching over your
        safety and our property.
      </p>
      <p>
        To make this registration as easy as possible, iO has developed a
        software tool that automates this process, this is the Visitor
        Administration Tool.
      </p>

      <p>
        <strong>
          How and which personal data does the Visitor Administration Tool
          process?
        </strong>
      </p>
      <p>
        When visiting the campus, a tablet on the reception desk gives you a
        choice between a web form for registration with facial recognition or
        for manual registration:
      </p>
      <UnorderedList>
        <li>
          If you choose the facial recognition option, you voluntarily provide
          us with a picture of your face by standing in front of the
          tablet&apos;s camera. You expressly consent to this option by
          accepting via an on-screen pop-up. The facial recognition software
          links the image&apos;s metadata to your name and a time, after which
          the photo is immediately deleted. Optionally, you can also enter your
          e-mail address or company. Your personal data is then linked to a time
          of day and stored in our database. Upon leaving the premises or a
          later visit, you can register with the same action.
        </li>
        <li>
          If you choose the manual registration option, it is only required to
          enter your name in a web form. Other details such as your e-mail
          address or company name are optional to enter. Your personal data will
          be linked to a time and then stored in our database.
        </li>
      </UnorderedList>

      <p>
        <strong>How long does iO keep your personal data?</strong>
      </p>
      <p>
        A visitor is considered inactive if they have not revisited one of our
        sites for more than two years. After this two-year period, inactive
        visitor records are deleted from our database.
      </p>

      <p>
        <strong>How are your personal data protected?</strong>
      </p>
      <p>
        iO has taken the necessary technical and organisational measures to
        protect the processing of (biometric) personal data. These measures are
        in line with ISO27001 certification that iO holds.
      </p>
      <p>
        As for the facial recognition app, additional security measures have
        been implemented. The Microsoft FaceAPI only stores derived data from
        the photos that are uploaded. Actual images cannot be reconstructed from
        this data; these are deleted immediately.
      </p>

      <p>
        <strong>With whom do we share data?</strong>
      </p>
      <p>
        The facial recognition app uses Microsoft FaceAPI. The personal data
        thus collected is not stored on the tablet but is transmitted directly
        to Microsoft&apos;s FaceAPI, where the photo is processed by their AI
        solution. Only the facial data required for later recognition is
        retained. Microsoft does not retain the original photo, nor can it be
        constructed from the facial recognition data. The facial recognition
        data is stored securely by Microsoft and cannot be viewed or retrieved
        from them.
      </p>

      <p>
        <strong>On what basis does iO process these personal data?</strong>
      </p>
      <p>iO processes personal data on various legal grounds:</p>
      <UnorderedList>
        <li>
          When you register manually, iO has a legitimate interest to process
          your name, e-mail address, details of the company you work for or any
          other contact details.
        </li>
        <li>
          Registration using facial recognition involves processing biometric
          personal data. This processing is done only with your explicit
          consent.
        </li>
      </UnorderedList>

      <p>
        <strong>What are your rights in relation to this processing?</strong>
      </p>
      <p>You have the right to:</p>
      <UnorderedList>
        <li>
          to request access to all the personal data that we process about you.
          However, requests for access that are clearly submitted with a view to
          causing us inconvenience or damage will not be dealt with.
        </li>
        <li>
          to request that any personal data about you that is incorrect or
          inaccurate be corrected free of charge.
        </li>
        <li>
          to withdraw previously granted permission for the processing of your
          personal data. You can always withdraw your permission by sending an
          email to legal@iodigital.com.
        </li>
        <li>
          to request that personal data relating to you be removed if this is no
          longer needed in the light of the objectives that are outlined in this
          Privacy Policy or if you withdraw your processing permission. However,
          you must consider that a removal request to us will be assessed in the
          light of statutory or regulatory obligations or administrative or
          judicial orders, which may prevent us from removing the respective
          personal data. Instead of requesting removal, you can also request
          that we restrict the processing or your personal data if (a) you
          dispute the correctness of such data, (b) the processing is unlawful
          or (c) the data is no longer necessary for the objectives stated but
          you need it to defend yourself in judicial proceedings.
        </li>
        <li>
          to object to the processing of personal data if you can show that
          there are serious and justified reasons regarding special
          circumstances warranting such an objection. However, if the envisaged
          processing is noted as direct marketing, you have the right to object
          to such processing free of charge and without giving any reason for
          this.
        </li>
        <li>
          If your personal data is processed on the basis of permission or on
          the basis of a contract where the data is processed automatically, you
          have the right to receive the personal data provided to us in a
          structured manner and in a generally used format that can be read by a
          machine and, if technically possible, you have the right to directly
          transmit such data to another service provider. We will be the only
          persons to assess the technical viability of this.
        </li>
      </UnorderedList>

      <p>
        <strong>File a complaint</strong>
      </p>
      <p>
        If you have a complaint (or would like more information) about our
        processing of your personal data, you can always contact us at
        legal@iodigital.com.
      </p>
      <p>
        If you remain dissatisfied with our response, you are free to lodge a
        complaint with the competent data protection authority. You can file a
        complaint at:
      </p>
      <UnorderedList>
        <li>For The Netherlands: Autoriteit Persoonsgegevens.</li>
        <li>For Belgium: Gegevensbeschermingsautorieit.</li>
      </UnorderedList>

      <p>
        <strong>Contact our Data Protection Officer</strong>
      </p>
      <p>Maxim Gernay, legal counsel and DPO, legal@iodigital.com</p>
    </PageTemplateClient>
  );
};
