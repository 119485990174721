import { useCountdown } from "../hooks/use-countdown";
import { CountdownProps } from "../types/CountdownProps";

export const Countdown = ({
  onFinished,
}: CountdownProps): React.ReactElement | null => {
  const ticks = useCountdown({
    onFinished,
    initialTicks: 3,
    tickDuration: 1000,
  });

  if (ticks === 0) {
    return null;
  }

  return (
    <div
      className={
        "flex items-center justify-center w-32 h-32 rounded-full bg-black/70 text-8xl text-white font-medium"
      }
    >
      {ticks}
    </div>
  );
};
