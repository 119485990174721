import React from "react";
import { twMerge } from "tailwind-merge";

type TableProps = {
  className?: string;
  children: React.ReactNode;
};

export const Table = ({ className, children }: TableProps) => (
  <div className={"overflow-x-auto rounded-lg"}>
    <table
      className={twMerge(
        "min-w-full leading-normal bg-white text-xl",
        className
      )}
    >
      {children}
    </table>
  </div>
);
