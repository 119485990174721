import client from "../../client";
import {
  useQuery,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import type { RetrieveOrganizersQueryResponse } from "../types/RetrieveOrganizers";
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";

type RetrieveOrganizersClient = typeof client<
  RetrieveOrganizersQueryResponse,
  never,
  never
>;
type RetrieveOrganizers = {
  data: RetrieveOrganizersQueryResponse;
  error: never;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: never;
  response: RetrieveOrganizersQueryResponse;
  client: {
    parameters: Partial<Parameters<RetrieveOrganizersClient>[0]>;
    return: Awaited<ReturnType<RetrieveOrganizersClient>>;
  };
};
export const retrieveOrganizersQueryKey = () =>
  [{ url: "/vat-client/organizers" }] as const;
export type RetrieveOrganizersQueryKey = ReturnType<
  typeof retrieveOrganizersQueryKey
>;
export function retrieveOrganizersQueryOptions(
  options: RetrieveOrganizers["client"]["parameters"] = {}
) {
  const queryKey = retrieveOrganizersQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        RetrieveOrganizers["data"],
        RetrieveOrganizers["error"]
      >({
        method: "get",
        url: `/vat-client/organizers`,
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @description Retrieve all possible meeting organizers (Usually the employees of the company)
 * @link /vat-client/organizers
 */
export function useRetrieveOrganizers<
  TData = RetrieveOrganizers["response"],
  TQueryData = RetrieveOrganizers["response"],
  TQueryKey extends QueryKey = RetrieveOrganizersQueryKey
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        RetrieveOrganizers["response"],
        RetrieveOrganizers["error"],
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: RetrieveOrganizers["client"]["parameters"];
  } = {}
): UseQueryResult<TData, RetrieveOrganizers["error"]> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? retrieveOrganizersQueryKey();
  const query = useQuery({
    ...(retrieveOrganizersQueryOptions(
      clientOptions
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">),
  }) as UseQueryResult<TData, RetrieveOrganizers["error"]> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
export const retrieveOrganizersSuspenseQueryKey = () =>
  [{ url: "/vat-client/organizers" }] as const;
export type RetrieveOrganizersSuspenseQueryKey = ReturnType<
  typeof retrieveOrganizersSuspenseQueryKey
>;
export function retrieveOrganizersSuspenseQueryOptions(
  options: RetrieveOrganizers["client"]["parameters"] = {}
) {
  const queryKey = retrieveOrganizersSuspenseQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        RetrieveOrganizers["data"],
        RetrieveOrganizers["error"]
      >({
        method: "get",
        url: `/vat-client/organizers`,
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @description Retrieve all possible meeting organizers (Usually the employees of the company)
 * @link /vat-client/organizers
 */
export function useRetrieveOrganizersSuspense<
  TData = RetrieveOrganizers["response"],
  TQueryKey extends QueryKey = RetrieveOrganizersSuspenseQueryKey
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        RetrieveOrganizers["response"],
        RetrieveOrganizers["error"],
        TData,
        TQueryKey
      >
    >;
    client?: RetrieveOrganizers["client"]["parameters"];
  } = {}
): UseSuspenseQueryResult<TData, RetrieveOrganizers["error"]> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? retrieveOrganizersSuspenseQueryKey();
  const query = useSuspenseQuery({
    ...(retrieveOrganizersSuspenseQueryOptions(
      clientOptions
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">),
  }) as UseSuspenseQueryResult<TData, RetrieveOrganizers["error"]> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
