import axios, { AxiosError } from "axios";
import { RequestConfig, ResponseConfig } from "@kubb/swagger-client/client";

export const setManagementToken = (token: string) => {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const setCLientHeader = () => {
  const sessionId = localStorage.getItem("sessionId");
  const sessionToken = localStorage.getItem("sessionToken");

  axiosInstance.defaults.headers.common["X-Session-Id"] = sessionId;
  axiosInstance.defaults.headers.common["X-Session-Token"] = sessionToken;
};

const resetClientHeaders = () => {
  localStorage.removeItem("sessionId");
  localStorage.removeItem("sessionToken");
};

// Declare the axiosClient function signature
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function axiosClient<TData, TError = unknown, TVariables = unknown>(
  config: RequestConfig<TVariables>
): Promise<ResponseConfig<TData>> {
  try {
    const response = await axiosInstance.request<TData>(config);
    return response;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.status === 401) {
      resetClientHeaders();
    }
    throw e;
  }
}

export {
  type RequestConfig,
  type ResponseConfig,
  axiosClient,
  axiosInstance,
  axiosClient as default,
};
