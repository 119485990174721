import React from "react";
import { twMerge } from "tailwind-merge";

type LabelProps = {
  children: React.ReactNode;
  className?: string;
  htmlFor?: string;
};

export const Label = ({ className, children, ...props }: LabelProps) => (
  <label className={twMerge("font-medium text-xl", className)} {...props}>
    {children}
  </label>
);
