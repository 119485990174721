import { NavLink } from "react-router-dom";
import { useAppData } from "../hooks/use-app-data";
import { ReactComponent as IOLogo } from "../svg/iO_logo.svg";
import { pages } from "../util/pages";
import { userRole } from "../api/__generated__/types";

export const ManagementHeader = () => {
  const { state } = useAppData();

  return (
    <div className="flex p-10 justify-start items-center">
      <IOLogo />
      <NavLink
        to={pages.MANAGEMENT_VISITORS}
        className={({ isActive }) =>
          `ml-8 text-xl ${isActive ? "underline" : ""}`
        }
      >
        Visitors
      </NavLink>
      {state.profile && state.profile.role === userRole.ADMIN && (
        <NavLink
          to={pages.MANAGEMENT_USERS}
          className={({ isActive }) =>
            `ml-8 text-xl ${isActive ? "underline" : ""}`
          }
        >
          Users
        </NavLink>
      )}
      {state.profile && state.profile.role === userRole.ADMIN && (
        <NavLink
          to={pages.MANAGEMENT_DEVICES}
          className={({ isActive }) =>
            `ml-8 text-xl ${isActive ? "underline" : ""}`
          }
        >
          Devices
        </NavLink>
      )}
    </div>
  );
};
