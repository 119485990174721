import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import CreatableSelect from "react-select/creatable";
import { Button } from "../../components/button";
import { Input } from "../../components/input";
import { Label } from "../../components/label";
import { PageTemplateClient } from "../../components/page-template-client";
import { actions } from "../../types/actions";
import { useAppData } from "../../hooks/use-app-data";
import { useFormInput } from "../../hooks/use-form-input";
import { defaultCustomStyles } from "../../styles/creatable-select";
import { ReactComponent as IconAddPhoto } from "../../svg/add_photo.svg";
import { addLeadingZero } from "../../util/add-leading-zero";
import { pages } from "../../util/pages";
import { Option } from "../../types/reactSelectOption";
import { SingleValue, MultiValue, ActionMeta } from "react-select";
import {
  useGetVisitorsClient,
  useManualVisitorHandling,
} from "../../api/__generated__/hooks/";
import { Visitor } from "../../api/__generated__/types";

export const ManualForm = () => {
  const { state, dispatch } = useAppData();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState(
    state.manualFormInputs.inputName || ""
  );
  const [showNameRequiredError, setShowNameRequiredError] = useState(false);
  const [showFullNameError, setShowFullNameError] = useState(false);
  const form = useRef<HTMLFormElement>(null);

  const today = new Date();
  const todayFullString = `${today.getFullYear()}-${addLeadingZero(
    today.getMonth() + 1
  )}-${addLeadingZero(today.getDate())}`;
  const {
    data: visitors,
    isError,
    error,
  } = useGetVisitorsClient({
    "day-start": todayFullString,
    "day-end": todayFullString,
  });
  const mappedVisitors = visitors?.map((visitor: Visitor) => ({
    label: visitor.name,
    value: visitor.name,
  }));

  useEffect(() => {
    if (isError) {
      console.error("Error fetching visitors", error);
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          code: "application_error",
          message: "Current visitors could not be retrieved",
        },
      });
    }
  }, [dispatch, error, isError]);

  const inputs = {
    id: useFormInput(state.manualFormInputs.inputId || ""),
    name: useFormInput(state.manualFormInputs.inputName || ""),
    email: useFormInput(state.manualFormInputs.inputEmail || ""),
    organization: useFormInput(state.manualFormInputs.inputOrganization || ""),
  };

  // This is needed to trigger html5 validation
  const onPointerDown = () => {
    if (form.current) {
      form.current.dispatchEvent(new Event("submit"));
    }
  };

  const resetInputInState = () => {
    dispatch({
      type: actions.SET_MANUAL_FORM_INPUTS,
      payload: {
        inputId: "",
        inputName: "",
        inputEmail: "",
        inputOrganization: "",
      },
    });
  };

  const manualVisitorMutation = useManualVisitorHandling({
    mutation: {
      onSuccess: (response) => {
        dispatch({ type: actions.SET_ERROR, payload: null });
        dispatch({ type: actions.SET_RECOGNIZED_VISITOR, payload: response });
        navigate(pages.RECOGNIZE_CONFIRM);
        resetInputInState();
        setIsLoading(false);
      },
      onError: (error) => {
        console.error("Oh no! there has been an error:", error);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message: "Oops, something went wrong",
          },
        });
      },
    },
  });

  const checkInOutVisitor = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setIsLoading(true);

    dispatch({
      type: actions.SET_MANUAL_FORM,
      payload: true,
    });

    const inputsData = {
      id: inputs.id.value,
      name: inputs.name.value,
      email: inputs.email.value,
      organization: inputs.organization.value,
      imageData:
        state.capturedImage === null ? null : state.capturedImage.image,
    };

    manualVisitorMutation.mutate(inputsData);
  };

  const backToStartHandler = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch({
      type: actions.SET_ERROR,
      payload: null,
    });
    resetInputInState();
    navigate(pages.HOME);
  };
  const handleNewVisitor = (value: string) => {
    inputs.name.setValue(value);
    setShowNameRequiredError(false);
    setShowFullNameError(false);
  };

  const handleSuggestedVisitor = (visitor: Visitor) => {
    if (visitor.id) inputs.id.setValue(visitor.id);
    if (visitor.name) inputs.name.setValue(visitor.name);
    if (visitor.email) inputs.email.setValue(visitor.email);
    if (visitor.organization)
      inputs.organization.setValue(visitor.organization);
    setShowNameRequiredError(false);
    setShowFullNameError(false);
  };

  const handleSelectedVisitor = (
    newValue: SingleValue<Option> | MultiValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => {
    if (newValue === null) {
      inputs.id.setValue("");
      inputs.name.setValue("");
      inputs.email.setValue("");
      inputs.organization.setValue("");
      return;
    }

    const selectedOption = newValue as Option;

    const visitor = visitors?.find(
      (visitor: Visitor) => visitor.name === selectedOption.label
    ) || {
      id: "",
      name: selectedOption.label,
      email: "",
      organization: "",
      visits: [],
    };

    if (actionMeta.action === "select-option") {
      handleSuggestedVisitor(visitor);
    } else {
      handleNewVisitor(inputs.name.value);
    }
  };

  const handleInputChange = (value: string, action: { action: string }) => {
    if (action.action !== "input-blur" && action.action !== "menu-close") {
      setInputValue(value);
      inputs.name.setValue(value);
    }
  };

  const faceRecognitionHandler = () => {
    if (!navigator.mediaDevices) {
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          code: "application_error",
          message: "Using the camera requires https",
        },
      });
      return;
    }
    // Set input in the application state so we can repopulate when picture is taken
    dispatch({
      type: actions.SET_MANUAL_FORM_INPUTS,
      payload: {
        inputId: inputs.id.value,
        inputName: inputs.name.value,
        inputEmail: inputs.email.value,
        inputOrganization: inputs.organization.value,
      },
    });
    dispatch({
      type: actions.SET_ERROR,
      payload: null,
    });
    navigate({ pathname: pages.RECOGNIZE, search: "?manual=true" });
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!inputs.name.value) {
      setShowNameRequiredError(true);
      return;
    }
    // Check for white space && 5 characters
    const trimmedInputFullName = inputs.name.value.trim();
    if (
      !/\s/.test(trimmedInputFullName) ||
      !/^.{5,}$/.test(trimmedInputFullName)
    ) {
      setShowFullNameError(true);
      return;
    }
    // This is needed to trigger html5 validation
    if (form.current && !form.current.reportValidity()) {
      return;
    }
    checkInOutVisitor(event);
  };

  const ManualFormFooter = () => (
    <div className="mb-6">
      <Button
        variant={"link"}
        onPointerDown={backToStartHandler}
        data-testid="btn-back-to-start"
      >
        Back to start
      </Button>
    </div>
  );
  return (
    <PageTemplateClient footer={<ManualFormFooter />}>
      <h3 data-testid="page-title" className={" mb-6 text-h3"}>
        Enter details
      </h3>

      {state.capturedImage ? (
        <div className="flex flex-row items-center justify-center mb-12 gap-8">
          <img
            className="border-black border-2 rounded-full w-40 h-40 object-cover -scale-x-100"
            src={state.capturedImage.imageWithMeta}
            alt="You"
          />
          <Button
            variant={"link"}
            onPointerDown={faceRecognitionHandler}
            data-testid="btn-change-photo"
          >
            Change picture
          </Button>
        </div>
      ) : (
        <div className="flex flex-row items-center  mb-12 gap-8">
          <Button
            variant={"link"}
            onPointerDown={faceRecognitionHandler}
            data-testid="btn-picture-frame"
            className={
              "bg-secondary-baseGreen border-black border-2 border-dashed rounded-full p-12"
            }
          >
            <IconAddPhoto />
          </Button>
          <div className="flex flex-col gap-1">
            <Button variant={"link"} onPointerDown={faceRecognitionHandler}>
              Take a picture?
            </Button>
            <p className={"text-base m-0"}>For a faster check-in next time</p>
          </div>
        </div>
      )}

      <form
        name="visitorForm"
        className="w-[55vw]"
        ref={form}
        onSubmit={submitForm}
      >
        <Input id="id" name="id" type="hidden" {...inputs.id.input} />

        <div className="space-y-6">
          <div>
            <Label htmlFor="name">Full name *</Label>
            <CreatableSelect
              inputId="name"
              name="name"
              options={mappedVisitors}
              onChange={handleSelectedVisitor}
              styles={defaultCustomStyles}
              placeholder="Full name"
              noOptionsMessage={() => "No suggestions"}
              formatCreateLabel={(label) => `"${label}"`}
              openMenuOnClick={false}
              menuIsOpen={inputValue.length >= 3}
              getOptionLabel={(option: Option) => option.label}
              onInputChange={handleInputChange}
              inputValue={inputValue}
              isClearable={true}
              backspaceRemovesValue={true}
              className={"h-14 text-h6 mt-2 w-full"}
              classNamePrefix={"react-select"}
            />
            {showNameRequiredError && (
              <div className={"input-error"}>Required</div>
            )}
            {showFullNameError && (
              <div className={"input-error"}>Please fill in your full name</div>
            )}
          </div>

          <div>
            <Label htmlFor="email">E-mail (optional)</Label>
            <Input
              id="email"
              name="email"
              type="E-mail"
              {...inputs.email.input}
              data-testid="input-email"
            />
          </div>

          <div>
            <Label htmlFor="organization">Organization (optional)</Label>
            <Input
              id="organization"
              name="organization"
              type="Organization"
              {...inputs.organization.input}
              data-testid="input-organization"
            />
          </div>
        </div>

        <Button
          variant={"primary"}
          className={"mt-12"}
          onPointerDown={onPointerDown}
          data-testid="btn-check-in-out"
          isLoading={isLoading}
        >
          Check in/out
        </Button>
      </form>
    </PageTemplateClient>
  );
};
