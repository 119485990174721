import client from "../../client";
import { useMutation } from "@tanstack/react-query";
import type {
  VisitorCheckInMutationRequest,
  VisitorCheckInMutationResponse,
  VisitorCheckInPathParams,
} from "../types/VisitorCheckIn";
import type { UseMutationOptions } from "@tanstack/react-query";

type VisitorCheckInClient = typeof client<
  VisitorCheckInMutationResponse,
  never,
  VisitorCheckInMutationRequest
>;
type VisitorCheckIn = {
  data: VisitorCheckInMutationResponse;
  error: never;
  request: VisitorCheckInMutationRequest;
  pathParams: VisitorCheckInPathParams;
  queryParams: never;
  headerParams: never;
  response: VisitorCheckInMutationResponse;
  client: {
    parameters: Partial<Parameters<VisitorCheckInClient>[0]>;
    return: Awaited<ReturnType<VisitorCheckInClient>>;
  };
};
/**
 * @description check in a visitor
 * @link /vat-client/visitors/:visitorId/checkin
 */
export function useVisitorCheckIn(
  visitorId: VisitorCheckInPathParams["visitorId"],
  options: {
    mutation?: UseMutationOptions<
      VisitorCheckIn["response"],
      VisitorCheckIn["error"],
      VisitorCheckIn["request"]
    >;
    client?: VisitorCheckIn["client"]["parameters"];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async (data) => {
      const res = await client<
        VisitorCheckIn["data"],
        VisitorCheckIn["error"],
        VisitorCheckIn["request"]
      >({
        method: "post",
        url: `/vat-client/visitors/${visitorId}/checkin`,
        data,
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}
