import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../components/button";
import { PageTemplateClient } from "../../components/page-template-client";
import { actions } from "../../types/actions";
import { useAppData } from "../../hooks/use-app-data";
import { pages } from "../../util/pages";

export const NotDetected = () => {
  const { state, dispatch } = useAppData();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // If we got to this page through the manual flow, then we should bring the
  // user back to the manual form if they try again
  const isManualFlow = searchParams.get("manual") === "true";

  const tryAgainHandler = () => {
    dispatch({
      type: actions.SET_CAPTURED_IMAGE,
      payload: null,
    });
    navigate({
      pathname: pages.RECOGNIZE,
      search: isManualFlow ? "?tips=true&manual=true" : "?tips=true",
    });
  };

  const manualHandler = () => {
    navigate(pages.RECOGNIZE_MANUAL_FORM);
  };

  const backToStartHandler = () => {
    navigate(pages.HOME);
  };

  const NoFaceFooter = () => (
    <Button variant={"link"} onPointerDown={backToStartHandler}>
      Back to start
    </Button>
  );

  return (
    <PageTemplateClient footer={<NoFaceFooter />}>
      <h1>Face not detected 🧐</h1>
      <p>We couldn't detect a face in the picture, could you try again?</p>
      {state.capturedImage && (
        <img
          className="border-black mt-8 border-2 rounded-full w-64 h-64 mx-auto object-cover -scale-x-100"
          src={state.capturedImage.imageWithMeta}
          alt="You"
        />
      )}
      <div className={"flex flex-col gap-8 items-center mt-12"}>
        <Button variant={"primary"} onPointerDown={tryAgainHandler}>
          Try again
        </Button>

        <Button variant={"secondary"} onPointerDown={manualHandler}>
          Manual
        </Button>
      </div>
    </PageTemplateClient>
  );
};
