import { Button } from "./button";
import { PageTemplateManagement } from "./page-template-management";
import { useAppData } from "../hooks/use-app-data";

export const NoAccess = () => {
  const { state } = useAppData();
  const refresh = () => {
    window.location.reload();
  };

  return (
    <PageTemplateManagement error={state.error}>
      <h1 className={"mb-6"}>⛔️ Unauthorized</h1>
      <p>
        You are not authorized to view this page. Please contact an
        administrator to request access.
      </p>
      <Button
        variant={"secondary"}
        onPointerDown={refresh}
        className={"py-2 w-48 text-lg"}
      >
        Refresh
      </Button>
    </PageTemplateManagement>
  );
};
