import React from "react";
import { ReactComponent as IOLogo } from "../svg/iO_logo.svg";

type PageTemplateClientProps = {
  children: React.ReactNode;
  footer?: React.ReactNode;
};

export const PageTemplateClient = ({
  children,
  footer,
}: PageTemplateClientProps) => (
  <div className="flex flex-col h-full w-full select-none">
    <div className="flex justify-end p-10 ">
      <IOLogo />
    </div>
    <main
      role="main"
      className="flex items-start flex-col flex-grow w-full px-10 justify-around pb-10"
    >
      <div className="overflow-auto">{children}</div>
    </main>
    {footer && (
      <footer className="flex flex-col mt-auto py-14 px-3 pl-10 items-start ">
        {footer}
      </footer>
    )}
  </div>
);
