import client from "../../client";
import { useMutation } from "@tanstack/react-query";
import type {
  VisitorCheckOutByAdminMutationRequest,
  VisitorCheckOutByAdminMutationResponse,
  VisitorCheckOutByAdminPathParams,
} from "../types/VisitorCheckOutByAdmin";
import type { UseMutationOptions } from "@tanstack/react-query";

type VisitorCheckOutByAdminClient = typeof client<
  VisitorCheckOutByAdminMutationResponse,
  never,
  VisitorCheckOutByAdminMutationRequest
>;
type VisitorCheckOutByAdmin = {
  data: VisitorCheckOutByAdminMutationResponse;
  error: never;
  request: VisitorCheckOutByAdminMutationRequest;
  pathParams: VisitorCheckOutByAdminPathParams;
  queryParams: never;
  headerParams: never;
  response: VisitorCheckOutByAdminMutationResponse;
  client: {
    parameters: Partial<Parameters<VisitorCheckOutByAdminClient>[0]>;
    return: Awaited<ReturnType<VisitorCheckOutByAdminClient>>;
  };
};
/**
 * @description check out a visitor
 * @link /vat-management/visitors/:visitorId/checkout
 */
export function useVisitorCheckOutByAdmin(
  visitorId: VisitorCheckOutByAdminPathParams["visitorId"],
  options: {
    mutation?: UseMutationOptions<
      VisitorCheckOutByAdmin["response"],
      VisitorCheckOutByAdmin["error"],
      VisitorCheckOutByAdmin["request"]
    >;
    client?: VisitorCheckOutByAdmin["client"]["parameters"];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async (data) => {
      const res = await client<
        VisitorCheckOutByAdmin["data"],
        VisitorCheckOutByAdmin["error"],
        VisitorCheckOutByAdmin["request"]
      >({
        method: "post",
        url: `/vat-management/visitors/${visitorId}/checkout`,
        data,
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}
