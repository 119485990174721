export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_SSO_CLIENTID,
    // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    authority: process.env.REACT_APP_SSO_AUTHORITY,
    redirectUri: process.env.REACT_APP_SSO_REDIRECTURI,
  },
  cache: {
    cacheLocation: process.env.REACT_APP_SSO_CACHELOCATION, // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const getScope = () => {
  if (!process.env.REACT_APP_SSO_SCOPES) {
    throw Error("REACT_APP_SSO_SCOPES is not defined");
  } else {
    return [process.env.REACT_APP_SSO_SCOPES];
  }
};
