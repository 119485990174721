import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

type ButtonProps = {
  children: React.ReactNode;
  variant: "primary" | "secondary" | "link";
  isLoading?: boolean;
  className?: string;
  disabled?: boolean;
  onPointerDown?: (event: React.PointerEvent<HTMLButtonElement>) => void;
  onMouseDown?: (event: React.PointerEvent<HTMLButtonElement>) => void;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, className, isLoading, children, ...props }: ButtonProps, ref) => {
    switch (variant) {
      case "primary":
        return (
          <button
            className={twMerge(
              "bg-primary-blue py-4 w-full text-2xl text-white font-medium rounded-full relative overflow-hidden transition disabled:bg-secondary-blue",
              className
            )}
            ref={ref}
            {...props}
          >
            {children}
            {isLoading && (
              <div className="flex flex-col absolute top-0 w-full">
                <div className="relative w-full rounded">
                  <div className="h-1 w-full rounded loading-animation"></div>
                </div>
              </div>
            )}
          </button>
        );
      case "secondary":
        return (
          <button
            className={twMerge(
              "border-grey-grey30 bg-grey-grey10  border-2 py-4 w-full text-2xl rounded-full",
              className
            )}
            ref={ref}
            {...props}
          >
            {children}
          </button>
        );
      case "link":
        return (
          <button
            className={twMerge("underline text-xl", className)}
            ref={ref}
            {...props}
          >
            {children}
          </button>
        );
      default:
        throw new Error("Variant prop for Button is required.");
    }
  }
);
