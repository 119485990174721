import { useState } from "react";

/**
 * Hook useFormInput.
 *
 * @example:
 * const username = useFormInput("initialValue");
 *
 * return <input type="text"
 *
 * @param initialValue - The initial value.
 * @returns {object} with props for a React Input element.
 */
export const useFormInput = (initialValue = "") => {
  const [value, setValue] = useState(initialValue || "");

  const onChangeHandler = (e: { target: { value: string } }) => {
    setValue(e.target.value || "");
  };

  return {
    value,
    setValue,
    input: {
      value: value || "",
      onChange: onChangeHandler,
    },
  };
};
