import { BrowserQRCodeReader } from "@zxing/browser";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { actions } from "../../types/actions";
import { useAppData } from "../../hooks/use-app-data";
import { ReactComponent as IOLogo } from "../../svg/iO_logo.svg";
import { ReactComponent as IOSlogan } from "../../svg/io_slogan.svg";
import { pages } from "../../util/pages";
import { useRegisterSession } from "../../api/__generated__/hooks/";

export const ScanQrCode = () => {
  const { dispatch } = useAppData();
  const navigate = useNavigate();
  const videoElement = useRef<HTMLVideoElement>(null);
  const [oneTimeSecret, setOneTimeSecret] = useState("");

  const { data: fetchedSessionIdAndToken, error } = useRegisterSession(
    { "X-Onetime-Token": oneTimeSecret },
    {
      query: {
        enabled: !!oneTimeSecret,
      },
    }
  );

  useEffect(() => {
    // "Registers" the session by retrieving the sessionId and sessionToken and storing them in localStorage.
    if (fetchedSessionIdAndToken) {
      // Store session id and token in localstorage for auth purposes
      localStorage.setItem("sessionId", fetchedSessionIdAndToken.sessionId);
      localStorage.setItem(
        "sessionToken",
        fetchedSessionIdAndToken.sessionToken
      );

      dispatch({
        type: actions.SET_CLIENT_SESSION,
        payload: fetchedSessionIdAndToken,
      });
      // Redirect to success page
      navigate(pages.CLIENT_REGISTER_SUCCCESS);
    }

    if (error) {
      console.error(error);
      dispatch({
        type: actions.SET_ERROR,
        payload: error,
      });
    }
  }, [fetchedSessionIdAndToken, dispatch, navigate, error]);

  useEffect(() => {
    const codeReader = new BrowserQRCodeReader();

    const tryParseQrCodeResultText = (resultText: string) => {
      try {
        const JSONobj = JSON.parse(resultText);
        if (!JSONobj.oneTimeSecret) {
          dispatch({
            type: actions.SET_ERROR,
            payload: {
              code: "application_error",
              message: "Scanned QR code is invalid!",
            },
          });
        }
        setOneTimeSecret(JSONobj.oneTimeSecret);
      } catch (e) {
        console.error("Tried parsing an invalid JSON string.");
      }
    };

    const scanCodeOnce = () => {
      if (videoElement.current) {
        // Setting the device ID as undefined automatically chooses the camera,
        // preferring the main environment facing camera (rear camera) if multiple are available.
        codeReader
          .decodeOnceFromVideoDevice(undefined, videoElement.current)
          .then((result) => {
            tryParseQrCodeResultText(result.getText());
          });
      }
    };
    scanCodeOnce();
  }, [dispatch, navigate]);

  return (
    <div className="flex flex-col h-full w-full relastive">
      <div className="flex justify-between p-10">
        <IOLogo />
        <IOSlogan />
      </div>

      <main
        role="main"
        className="flex flex-col flex-grow w-full justify-center items-center overflow-hidden"
      >
        <div className="relative w-[500px] h-[500px] overflow-hidden">
          <video
            id="qr-scan-video"
            width="500"
            height="500"
            ref={videoElement}
            controls={false}
            autoPlay
            className="relative"
          />
          <div className="absolute w-1/2 h-1/2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className={"absolute top-0 left-0 w-16 h-2 bg-primary-blue"} />
            <div className={"absolute top-0 left-0 w-2 h-16 bg-primary-blue"} />

            <div
              className={"absolute top-0 right-0 w-16 h-2 bg-primary-blue"}
            />
            <div
              className={"absolute top-0 right-0 w-2 h-16 bg-primary-blue"}
            />

            <div
              className={"absolute bottom-0 left-0 w-16 h-2 bg-primary-blue"}
            />
            <div
              className={"absolute bottom-0 left-0 w-2 h-16 bg-primary-blue"}
            />

            <div
              className={"absolute bottom-0 right-0 w-16 h-2 bg-primary-blue"}
            />
            <div
              className={"absolute bottom-0 right-0 w-2 h-16 bg-primary-blue"}
            />
          </div>
        </div>
        <h1 className={"mt-12"}>Scan QR code</h1>
      </main>
    </div>
  );
};
