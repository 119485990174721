import client from "../../client";
import {
  useQuery,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import type {
  GetClientsManagementQueryResponse,
  GetClientsManagementQueryParams,
} from "../types/GetClientsManagement";
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";

type GetClientsManagementClient = typeof client<
  GetClientsManagementQueryResponse,
  never,
  never
>;
type GetClientsManagement = {
  data: GetClientsManagementQueryResponse;
  error: never;
  request: never;
  pathParams: never;
  queryParams: GetClientsManagementQueryParams;
  headerParams: never;
  response: GetClientsManagementQueryResponse;
  client: {
    parameters: Partial<Parameters<GetClientsManagementClient>[0]>;
    return: Awaited<ReturnType<GetClientsManagementClient>>;
  };
};
export const getClientsManagementQueryKey = (
  params?: GetClientsManagement["queryParams"]
) => [{ url: "/vat-management/clients" }, ...(params ? [params] : [])] as const;
export type GetClientsManagementQueryKey = ReturnType<
  typeof getClientsManagementQueryKey
>;
export function getClientsManagementQueryOptions(
  params?: GetClientsManagement["queryParams"],
  options: GetClientsManagement["client"]["parameters"] = {}
) {
  const queryKey = getClientsManagementQueryKey(params);
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetClientsManagement["data"],
        GetClientsManagement["error"]
      >({
        method: "get",
        url: `/vat-management/clients`,
        params,
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @description Get all clients or get clients matching search param
 * @link /vat-management/clients
 */
export function useGetClientsManagement<
  TData = GetClientsManagement["response"],
  TQueryData = GetClientsManagement["response"],
  TQueryKey extends QueryKey = GetClientsManagementQueryKey
>(
  params?: GetClientsManagement["queryParams"],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetClientsManagement["response"],
        GetClientsManagement["error"],
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: GetClientsManagement["client"]["parameters"];
  } = {}
): UseQueryResult<TData, GetClientsManagement["error"]> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getClientsManagementQueryKey(params);
  const query = useQuery({
    ...(getClientsManagementQueryOptions(
      params,
      clientOptions
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">),
  }) as UseQueryResult<TData, GetClientsManagement["error"]> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
export const getClientsManagementSuspenseQueryKey = (
  params?: GetClientsManagement["queryParams"]
) => [{ url: "/vat-management/clients" }, ...(params ? [params] : [])] as const;
export type GetClientsManagementSuspenseQueryKey = ReturnType<
  typeof getClientsManagementSuspenseQueryKey
>;
export function getClientsManagementSuspenseQueryOptions(
  params?: GetClientsManagement["queryParams"],
  options: GetClientsManagement["client"]["parameters"] = {}
) {
  const queryKey = getClientsManagementSuspenseQueryKey(params);
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetClientsManagement["data"],
        GetClientsManagement["error"]
      >({
        method: "get",
        url: `/vat-management/clients`,
        params,
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @description Get all clients or get clients matching search param
 * @link /vat-management/clients
 */
export function useGetClientsManagementSuspense<
  TData = GetClientsManagement["response"],
  TQueryKey extends QueryKey = GetClientsManagementSuspenseQueryKey
>(
  params?: GetClientsManagement["queryParams"],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetClientsManagement["response"],
        GetClientsManagement["error"],
        TData,
        TQueryKey
      >
    >;
    client?: GetClientsManagement["client"]["parameters"];
  } = {}
): UseSuspenseQueryResult<TData, GetClientsManagement["error"]> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getClientsManagementSuspenseQueryKey(params);
  const query = useSuspenseQuery({
    ...(getClientsManagementSuspenseQueryOptions(
      params,
      clientOptions
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">),
  }) as UseSuspenseQueryResult<TData, GetClientsManagement["error"]> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
