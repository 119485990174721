import { StateType } from "../types/stateType";

export const actions = {
  SET_RECOGNIZED_VISITOR: "SET_RECOGNIZED_VISITOR",
  SET_ERROR: "SET_ERROR",
  SET_CAPTURED_IMAGE: "SET_CAPTURED_IMAGE",
  SET_MANUAL_FORM: "SET_MANUAL_FORM",
  SET_MANUAL_FORM_INPUTS: "SET_MANUAL_FORM_INPUTS",
  TOGGLE_FETCH_CURRENT_VISITORS: "TOGGLE_FETCH_CURRENT_VISITORS",
  SET_USERS: "SET_USERS",
  SET_CLIENTS: "SET_CLIENTS",
  SET_ROLES: "SET_ROLES",
  SET_LOCATIONS: "SET_LOCATIONS",
  SET_PROFILE: "SET_PROFILE",
  SET_IO_ORGANIZERS: "SET_IO_ORGANIZERS",
  SET_QR_DATA: "SET_QR_DATA",
  SET_CLIENT_SESSION: "SET_CLIENT_SESSION",
} as const;

// Defining each type based on the stateType
type RecognizedVisitorPayload = StateType["recognizedVisitor"];
type ErrorPayload = StateType["error"];
type CapturedImagePayload = StateType["capturedImage"];
type ManualFormPayload = StateType["manualForm"];
type ManualFormInputsPayload = StateType["manualFormInputs"];
type ToggleFetchCurrentVisitorsPayload =
  StateType["toggleFetchCurrentVisitors"];
type LocationsPayload = StateType["locations"];
type ClientsPayload = StateType["clients"];
type UsersPayload = StateType["users"];
type RolesPayload = StateType["roles"];
type ProfilePayload = StateType["profile"];
type OrganizersPayload = StateType["organizers"];
type QRDataPayload = StateType["qrData"];
type ClientSessionPayload = StateType["clientSession"];

interface RecognizedVisitorAction {
  type: typeof actions.SET_RECOGNIZED_VISITOR;
  payload: RecognizedVisitorPayload;
}

interface ErrorAction {
  type: typeof actions.SET_ERROR;
  payload: ErrorPayload;
}

interface CapturedImageAction {
  type: typeof actions.SET_CAPTURED_IMAGE;
  payload: CapturedImagePayload;
}

interface ManualFormAction {
  type: typeof actions.SET_MANUAL_FORM;
  payload: ManualFormPayload;
}

interface ManualFormInputsAction {
  type: typeof actions.SET_MANUAL_FORM_INPUTS;
  payload: ManualFormInputsPayload;
}

interface ToggleFetchCurrentVisitorsAction {
  type: typeof actions.TOGGLE_FETCH_CURRENT_VISITORS;
  payload: ToggleFetchCurrentVisitorsPayload;
}

interface LocationsAction {
  type: typeof actions.SET_LOCATIONS;
  payload: LocationsPayload;
}

interface ClientsAction {
  type: typeof actions.SET_CLIENTS;
  payload: ClientsPayload;
}

interface UsersAction {
  type: typeof actions.SET_USERS;
  payload: UsersPayload;
}

interface RolesAction {
  type: typeof actions.SET_ROLES;
  payload: RolesPayload;
}

interface ProfileAction {
  type: typeof actions.SET_PROFILE;
  payload: ProfilePayload;
}

interface OrganizersAction {
  type: typeof actions.SET_IO_ORGANIZERS;
  payload: OrganizersPayload;
}

interface QRDataAction {
  type: typeof actions.SET_QR_DATA;
  payload: QRDataPayload;
}

interface ClientSessionAction {
  type: typeof actions.SET_CLIENT_SESSION;
  payload: ClientSessionPayload;
}

export type Action =
  | RecognizedVisitorAction
  | ErrorAction
  | CapturedImageAction
  | ManualFormAction
  | ManualFormInputsAction
  | ToggleFetchCurrentVisitorsAction
  | LocationsAction
  | ClientsAction
  | UsersAction
  | RolesAction
  | ProfileAction
  | OrganizersAction
  | QRDataAction
  | ClientSessionAction;
