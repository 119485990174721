import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router";
import { Loading } from "./components/loading";
import { PageNotFound } from "./components/page-not-found";
import { actions } from "./types/actions";
import { useAppData } from "./hooks/use-app-data";
import { ScanQrCode } from "./pages/client-registration/qrcode-scan";
import { QrCodeScanInformation } from "./pages/client-registration/qrcode-scan-information";
import { QrCodeScanSuccess } from "./pages/client-registration/qrcode-scan-success";
import { Home } from "./pages/home";
import { PrivacyPolicy } from "./pages/privacy-policy";
import { CheckedIn } from "./pages/visitor/checked-in";
import { CheckedOut } from "./pages/visitor/checked-out";
import { ManualForm } from "./pages/visitor/manual-form";
import { NotDetected } from "./pages/visitor/not-detected";
import { NotRecognized } from "./pages/visitor/not-recognized";
import { RecognizeConfirmation } from "./pages/visitor/recognize-confirmation";
import { VideoStreamCapture } from "./pages/visitor/video-stream-capture";
import { pages } from "./util/pages";
import { isLocalhost } from "./util/check-environment";
import { getClientDetails } from "./api/__generated__/axios-client";
import { setCLientHeader } from "./api/client";
import { ErrorHandling } from "./components/error-handling";

const reloadToUpdateFrontend = () => {
  const now = new Date();
  const midNight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1,
    0,
    0,
    0
  );
  const msToWait = midNight.getTime() - now.getTime();
  setTimeout(() => window.location.reload(), msToWait);
};

const prepareLocalSession = () => {
  const sessionId = "be89225a-94a8-49dc-ab1b-bd029107cc47";
  const sessionToken = "f1bfde6e-b2f0-497a-bbb1-683a5a4e644e";

  localStorage.setItem("sessionId", sessionId);
  localStorage.setItem("sessionToken", sessionToken);
};

export const App = () => {
  const { state, dispatch } = useAppData();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  isLocalhost() && prepareLocalSession();

  useEffect(() => {
    const sessionId = localStorage.getItem("sessionId");
    const sessionToken = localStorage.getItem("sessionToken");
    setIsLoading(true);

    // No session id or token present in localstorage, meaning client is not registered.
    if (!sessionId || !sessionToken) {
      // Redirect to qr info page, where the client can scan a qr code and register again.
      setIsLoading(false);
      navigate(pages.CLIENT_REGISTER);
      return;
    }

    setCLientHeader();

    getClientDetails(sessionId)
      .then((fetchedSession) => {
        dispatch({
          type: actions.SET_CLIENT_SESSION,
          payload: fetchedSession,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setIsLoading(false);
          navigate(pages.CLIENT_REGISTER);
        } else {
          console.error("Session could not be registered", error);
          dispatch({
            type: actions.SET_ERROR,
            payload: {
              code: "application_error",
              message: "Something went wrong, please try again.",
            },
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    // Only re-render on toggleFetchCurrentVisitors change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.toggleFetchCurrentVisitors]);

  useEffect(() => {
    reloadToUpdateFrontend();
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <div className={"app h-full"}>
      <ErrorHandling error={state.error} />
      <Routes>
        <Route index element={<Home />} />
        <Route path={pages.RECOGNIZE} element={<VideoStreamCapture />} />
        <Route
          path={pages.RECOGNIZE_CONFIRM}
          element={<RecognizeConfirmation />}
        />
        <Route
          path={pages.RECOGNIZE_NOT_RECOGNIZED}
          element={<NotRecognized />}
        />
        <Route path={pages.RECOGNIZE_NOT_DETECTED} element={<NotDetected />} />
        <Route path={pages.CHECKED_IN} element={<CheckedIn />} />
        <Route path={pages.CHECKED_OUT} element={<CheckedOut />} />
        <Route path={pages.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={pages.RECOGNIZE_MANUAL_FORM} element={<ManualForm />} />
        <Route
          path={pages.CLIENT_REGISTER}
          element={<QrCodeScanInformation />}
        />
        <Route path={pages.CLIENT_REGISTER_SCAN} element={<ScanQrCode />} />
        <Route
          path={pages.CLIENT_REGISTER_SUCCCESS}
          element={<QrCodeScanSuccess />}
        />
        <Route path={"*"} element={<PageNotFound />} />
      </Routes>
    </div>
  );
};
