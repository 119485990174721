import { useContext } from "react";
import { AppContext } from "../context/app-context";

export const useAppData = () => {
  const context = useContext(AppContext);
  // Ensure that context is not null before accessing it
  if (!context) {
    throw Error("useAppData must be used inside the AppContext");
  }
  return context;
};
