import client from "../../client";
import { useMutation } from "@tanstack/react-query";
import type {
  VisitorCheckOutMutationRequest,
  VisitorCheckOutMutationResponse,
  VisitorCheckOutPathParams,
} from "../types/VisitorCheckOut";
import type { UseMutationOptions } from "@tanstack/react-query";

type VisitorCheckOutClient = typeof client<
  VisitorCheckOutMutationResponse,
  never,
  VisitorCheckOutMutationRequest
>;
type VisitorCheckOut = {
  data: VisitorCheckOutMutationResponse;
  error: never;
  request: VisitorCheckOutMutationRequest;
  pathParams: VisitorCheckOutPathParams;
  queryParams: never;
  headerParams: never;
  response: VisitorCheckOutMutationResponse;
  client: {
    parameters: Partial<Parameters<VisitorCheckOutClient>[0]>;
    return: Awaited<ReturnType<VisitorCheckOutClient>>;
  };
};
/**
 * @description check out a visitor
 * @link /vat-client/visitors/:visitorId/checkout
 */
export function useVisitorCheckOut(
  visitorId: VisitorCheckOutPathParams["visitorId"],
  options: {
    mutation?: UseMutationOptions<
      VisitorCheckOut["response"],
      VisitorCheckOut["error"],
      VisitorCheckOut["request"]
    >;
    client?: VisitorCheckOut["client"]["parameters"];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async (data) => {
      const res = await client<
        VisitorCheckOut["data"],
        VisitorCheckOut["error"],
        VisitorCheckOut["request"]
      >({
        method: "post",
        url: `/vat-client/visitors/${visitorId}/checkout`,
        data,
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}
