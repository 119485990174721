import { useEffect, useState } from "react";
import { useMsalAuthentication, useAccount, useMsal } from "@azure/msal-react";
import { InteractionType, InteractionStatus } from "@azure/msal-browser";
import { getScope } from "../util/auth-config";
import { useAppData } from "../hooks/use-app-data";
import { actions } from "../types/actions";
import { useGetLoggedInUser } from "../api/__generated__/hooks";
import { setManagementToken } from "../api/client";

type RequireProfileProps = {
  children: React.ReactNode;
};

export const RequireProfile = ({ children }: RequireProfileProps) => {
  const { instance, inProgress, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  useMsalAuthentication(InteractionType.Redirect);
  const { dispatch } = useAppData();
  const { data: fetchedProfile, error } = useGetLoggedInUser();
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    const fetchProfile = () => {
      if (fetchedProfile) {
        dispatch({
          type: actions.SET_PROFILE,
          payload: fetchedProfile,
        });
      }
      if (error) {
        console.error("Current user could not be retrieved", error);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message: "Current user could not be retrieved",
          },
        });
      }
    };

    const acquireToken = () => {
      if (account && inProgress === InteractionStatus.None) {
        instance
          .acquireTokenSilent({
            scopes: getScope(),
            account,
          })
          .then((tokenResponse) => {
            setIsAvailable(true);
            setManagementToken(tokenResponse.accessToken);
            fetchProfile();
          })
          .catch((error) => {
            console.error("Token could not be retrieved silently", error);
            instance.acquireTokenRedirect({
              scopes: getScope(),
              account,
            });
          });
      }
    };

    if (account) {
      acquireToken();
    }
  }, [account, inProgress, instance, dispatch, fetchedProfile, error]);

  return isAvailable ? <>{children}</> : null;
};
