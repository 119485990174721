import React from "react";
import { twMerge } from "tailwind-merge";

type UnorderedListProps = {
  className?: string;
  children: React.ReactNode;
};

export const UnorderedList = ({
  className,
  children,
  ...props
}: UnorderedListProps) => (
  <ul
    className={twMerge("list-disc ml-6 mt-2 mb-4 text-left text-xl", className)}
    {...props}
  >
    {children}
  </ul>
);
