import { useNavigate } from "react-router";
import { Button } from "../../components/button";
import { PageTemplateClient } from "../../components/page-template-client";
import { pages } from "../../util/pages";

export const QrCodeScanInformation = () => {
  const navigate = useNavigate();

  const openScanQrCodePage = () => {
    navigate(pages.CLIENT_REGISTER_SCAN);
  };

  return (
    <PageTemplateClient>
      <h1>Device not registered</h1>
      <div className="flex flex-col gap-12 mt-12">
        <p>
          This device is currently not registered as a balietool device. Please
          register a device in the management console on your computer. When you
          are ready you can press the button below to scan the generated QR-code
          on your computer.
        </p>

        <Button onPointerDown={openScanQrCodePage} variant="primary">
          Scan QR code
        </Button>
      </div>
    </PageTemplateClient>
  );
};
