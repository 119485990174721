import client from "../../client";
import type { ResponseConfig } from "../../client";
import type {
  GetClientDetailsQueryResponse,
  GetClientDetailsPathParams,
} from "../types/GetClientDetails";

/**
 * @summary Gets the current client details with the stored session token
 * @link /vat-client/sessions/:sessionId
 */
export async function getClientDetails(
  sessionId: GetClientDetailsPathParams["sessionId"],
  options: Partial<Parameters<typeof client>[0]> = {}
): Promise<ResponseConfig<GetClientDetailsQueryResponse>["data"]> {
  const res = await client<GetClientDetailsQueryResponse>({
    method: "get",
    url: `/vat-client/sessions/${sessionId}`,
    ...options,
  });
  return res.data;
}
