import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Button } from "../../components/button";
import { Label } from "../../components/label";
import { PageTemplateClient } from "../../components/page-template-client";
import { actions } from "../../types/actions";
import { useAppData } from "../../hooks/use-app-data";
import { pages } from "../../util/pages";
import {
  useVisitorCheckIn,
  useVisitorCheckOut,
} from "../../api/__generated__/hooks/";
import Select, { SingleValue } from "react-select";
import { defaultCustomStyles } from "../../styles/creatable-select";
import { Option } from "../../types/reactSelectOption";

export const RecognizeConfirmation = () => {
  const { state, dispatch } = useAppData();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const form = useRef<HTMLFormElement>(null);
  const dataList = useRef<HTMLDataListElement>(null);
  const [inputValue, setInputValue] = useState("");

  const handleTryAgain = (e: React.PointerEvent) => {
    e.preventDefault();
    navigate({ pathname: pages.RECOGNIZE, search: "?tips=true" });
  };

  // This is needed to trigger html5 validation
  const onPointerDown = () => {
    if (form.current) {
      form.current.dispatchEvent(new Event("submit"));
    }
  };

  const validateOrganizer = (event: React.FormEvent<HTMLFormElement>) => {
    const target = event.target as typeof event.target & {
      appointmentWith: { value: string };
    };
    if (target.appointmentWith && state.organizers) {
      return state.organizers.find(
        (item) => item.name === target.appointmentWith.value
      );
    }
    // There is no organizer input field on checkout
    return undefined;
  };

  useEffect(() => {
    // Checking if recognized visitor is null
    if (!state.recognizedVisitor) {
      console.error("Error: recognized visitor is null");
      const message = `We couldn't process your action. Please try again.`;
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          code: "application_error",
          message,
        },
      });
      navigate(pages.HOME);
      return;
    }
    // Immediately follow checkout flow when manual flow is used
    if (
      state.recognizedVisitor &&
      state.recognizedVisitor.action.actionType === "checkout" &&
      state.manualForm === true
    ) {
      submitForm();
    }
    // Only trigger once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRecognizedVisitor = (
    event?: React.FormEvent<HTMLFormElement>
  ) => {
    if (event) event.preventDefault();
    const organizer = event ? validateOrganizer(event) : undefined;
    if (
      !organizer &&
      state.recognizedVisitor?.action.actionType === "checkin"
    ) {
      setShowError(true);
      return;
    }

    setIsLoading(true);
    // Add captured image when available to improve recognition and for future visits.
    const jsonData = {
      imageData: state.capturedImage ? state.capturedImage.image : undefined,
      appointmentWith: organizer,
      checkInLocation: state.clientSession?.location ?? undefined,
    };

    if (state.recognizedVisitor?.action?.actionType === "checkin") {
      checkInMutation.mutate(jsonData);
    } else {
      checkOutMutation.mutate(jsonData);
    }
  };

  const handleSuccess = (actionType: string) => {
    setIsLoading(false);
    // Reset manual form flow after success
    dispatch({
      type: actions.SET_MANUAL_FORM,
      payload: false,
    });
    // Trigger fetching the users in App.
    dispatch({
      type: actions.TOGGLE_FETCH_CURRENT_VISITORS,
      payload: !state.toggleFetchCurrentVisitors,
    });
    navigate(actionType === "checkin" ? pages.CHECKED_IN : pages.CHECKED_OUT, {
      replace: true,
    });
  };

  const handleError = (actionType: string, error: never) => {
    const message = `${actionType} did not succeed`;
    console.error(message, error);
    dispatch({
      type: actions.SET_ERROR,
      payload: { code: "application_error", message },
    });
    // Here we define the error, because it goes to the home page. Unlike other errors
    navigate(pages.HOME);
  };

  const checkInMutation = useVisitorCheckIn(
    state.recognizedVisitor ? state.recognizedVisitor.visitor.id : "",
    {
      mutation: {
        onSuccess: () => handleSuccess("checkin"),
        onError: (error) => handleError("checkin", error),
      },
    }
  );

  const checkOutMutation = useVisitorCheckOut(
    state.recognizedVisitor ? state.recognizedVisitor.visitor.id : "",
    {
      mutation: {
        onSuccess: () => handleSuccess("checkout"),
        onError: (error) => handleError("checkout", error),
      },
    }
  );

  const submitForm = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) event.preventDefault();
    // This is needed to trigger html5 validation
    if (form.current && !form.current.reportValidity()) {
      return;
    }
    handleRecognizedVisitor(event);
  };

  const manualHandler = (e: React.PointerEvent) => {
    e.preventDefault();
    navigate(pages.RECOGNIZE_MANUAL_FORM);
  };

  const nameHasSpace =
    state.recognizedVisitor?.visitor.name.indexOf(" ") !== -1;
  const firstName = nameHasSpace
    ? state.recognizedVisitor?.visitor.name.split(" ")[0]
    : state.recognizedVisitor?.visitor.name;

  const backToStartHandler = (e: React.PointerEvent) => {
    e.preventDefault();
    navigate(pages.HOME);
  };

  const handleSelectedOrganizer = (selectedOption: SingleValue<Option>) => {
    const organizer = selectedOption ? selectedOption.value : "";
    if (dataList.current) {
      dataList.current.setAttribute(
        "id",
        organizer.length > 2 ? "organizers" : ""
      );
    }
    setShowError(false);
  };

  const RecognizeConfirmationFooter = () => {
    if (!state.manualForm) {
      return (
        <div className="mb-6">
          <Button
            variant={"link"}
            onPointerDown={manualHandler}
            data-testid="btn-back-to-start"
          >
            Manual
          </Button>
        </div>
      );
    } else {
      return (
        <div className="mb-6">
          <Button
            variant={"link"}
            onPointerDown={backToStartHandler}
            data-testid="btn-back-to-start"
          >
            Back to start
          </Button>
        </div>
      );
    }
  };

  return (
    <PageTemplateClient footer={<RecognizeConfirmationFooter />}>
      <div className="mb-20">
        <h1 data-testid="greeting" className={"text-mb-8"}>
          Hello {firstName} 👋
        </h1>
      </div>

      {state.capturedImage && (
        <div className="flex flex-row items-center my-12">
          <img
            className="border-black border-2 rounded-full w-40 h-40 object-cover -scale-x-100"
            src={state.capturedImage.imageWithMeta}
            alt="You"
          />
          <div className="flex flex-col ml-10">
            <p>{state.recognizedVisitor?.visitor.name}</p>
          </div>
        </div>
      )}
      <div>
        <form
          name="visitorForm"
          onSubmit={submitForm}
          ref={form}
          className="flex flex-col w-[55vw]"
        >
          {state.organizers &&
            state.recognizedVisitor?.action.actionType === "checkin" && (
              <div>
                <Label htmlFor="appointmentWith">Appointment with:</Label>
                <Select
                  id="appointmentWith"
                  name="appointmentWith"
                  inputId="appointmentWithInputId"
                  options={state.organizers.map((organizer) => ({
                    value: organizer.name as string,
                    label: organizer.name as string,
                  }))}
                  isSearchable
                  isClearable
                  onChange={(e) => {
                    handleSelectedOrganizer(e);
                  }}
                  placeholder="Full name"
                  onInputChange={(inputValue) => setInputValue(inputValue)}
                  menuIsOpen={inputValue.length >= 3}
                  styles={{
                    ...defaultCustomStyles,
                    dropdownIndicator: (provided) => {
                      const shouldDisplayIndicator = inputValue.length >= 3;
                      return {
                        ...provided,
                        display: shouldDisplayIndicator ? "block" : "none",
                      };
                    },
                  }}
                  className={"mt-2"}
                  menuPortalTarget={document.body}
                />
                {showError && <div className={"input-error"}>Required</div>}
              </div>
            )}
          {state.organizers ||
          state.recognizedVisitor?.action.actionType === "checkout" ? (
            <Button
              variant={"primary"}
              className={"my-6"}
              onPointerDown={onPointerDown}
              data-testid="btn-submit-check-in-out"
              isLoading={isLoading}
            >
              {state.recognizedVisitor?.action.actionType === "checkin"
                ? "Check in"
                : "Check out"}
            </Button>
          ) : (
            <p className="text-2xl">
              Unfortunately we can't check you in because the list of organizers
              couldn't be retrieved.
            </p>
          )}
        </form>

        {state.manualForm !== true && (
          <Button variant={"secondary"} onPointerDown={handleTryAgain}>
            This is not me, try again
          </Button>
        )}
      </div>
    </PageTemplateClient>
  );
};
