import React from "react";
import { ManagementHeader } from "./management-header";

type PageTemplateManagementProps = {
  children: React.ReactNode;
  footer?: React.ReactNode;
  error: { message: string } | null;
};

export const PageTemplateManagement = ({
  children,
  footer,
  error,
}: PageTemplateManagementProps) => (
  <div className="flex flex-col h-full">
    {error && (
      <div className="absolute left-0 top-0 right-0 text-center p-2 bg-red text-white">
        {error.message}
      </div>
    )}
    <ManagementHeader />
    <div className={"grid h-full"}>
      <div className={"w-full min-w-[400px] px-14 flex flex-col"}>
        {children}
      </div>
    </div>

    {footer && <footer className="p-14 grid justify-center">{footer}</footer>}
  </div>
);
