import React from "react";
import { useNavigate } from "react-router";
import { Button } from "../../components/button";
import { PageTemplateClient } from "../../components/page-template-client";
import { actions } from "../../types/actions";
import { useAppData } from "../../hooks/use-app-data";
import { pages } from "../../util/pages";

export const NotRecognized = () => {
  const { dispatch } = useAppData();
  const navigate = useNavigate();

  const tryAgainHandler = (e: React.PointerEvent) => {
    e.preventDefault();
    if (!navigator.mediaDevices) {
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          code: "application_error",
          message: "Face recognition requires https",
        },
      });
      return;
    }
    dispatch({
      type: actions.SET_ERROR,
      payload: null,
    });
    navigate(pages.RECOGNIZE);
  };

  const manualHandler = (e: React.PointerEvent) => {
    e.preventDefault();
    dispatch({
      type: actions.SET_ERROR,
      payload: null,
    });
    navigate(pages.RECOGNIZE_MANUAL_FORM);
  };

  const backToStartHandler = (e: React.PointerEvent) => {
    e.preventDefault();
    dispatch({
      type: actions.SET_ERROR,
      payload: null,
    });
    navigate(pages.HOME);
  };

  const NotFoundFooter = () => (
    <Button variant={"link"} onPointerDown={backToStartHandler}>
      Back to start
    </Button>
  );

  return (
    <PageTemplateClient footer={<NotFoundFooter />}>
      <div className="flex flex-col">
        <div className="flex flex-col w-[55vw]">
          <h1 className="text-h1">🤔 Hmm </h1>
          <p className="text-h5">
            We weren't able to identify you yet. <br />
            Have you checked in with us before?
          </p>
        </div>

        <div className={"flex flex-col gap-4 items-center mt-12 w-full"}>
          <Button variant={"primary"} onPointerDown={tryAgainHandler}>
            Try again
          </Button>
          <Button variant={"secondary"} onPointerDown={manualHandler}>
            Manual
          </Button>
          <Button variant={"secondary"} onPointerDown={manualHandler}>
            First time here?
          </Button>
        </div>
      </div>
    </PageTemplateClient>
  );
};
