export const pages = {
  // Management
  MANAGEMENT: "/management",
  MANAGEMENT_VISITORS: "/management/visitors",
  MANAGEMENT_USERS: "/management/users",
  MANAGEMENT_DEVICES: "/management/devices",
  MANAGEMENT_DEVICES_REGISTER: "/management/devices/register",
  __LEGACY_MANAGEMENT: "/overview",
  __LEGACY_MANAGEMENT_USERS: "/user-management",
  __LEGACY_MANAGEMENT_DEVICES: "/device-management",

  // Visitor
  HOME: "/",
  RECOGNIZE: "/recognize",
  RECOGNIZE_CONFIRM: "/recognize/confirm",
  RECOGNIZE_NOT_RECOGNIZED: "/recognize/not-recognized",
  RECOGNIZE_NOT_DETECTED: "/recognize/not-detected",
  RECOGNIZE_MANUAL_FORM: "/recognize/manual",
  CHECKED_IN: "/checked-in",
  CHECKED_OUT: "/checked-out",
  PRIVACY_POLICY: "/privacy",
  CLIENT_REGISTER: "/register",
  CLIENT_REGISTER_SCAN: "/register/scan",
  CLIENT_REGISTER_SUCCCESS: "/register/success",
};
