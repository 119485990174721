import { InteractionType } from "@azure/msal-browser";
import { useMsalAuthentication } from "@azure/msal-react";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Select, { SingleValue } from "react-select";
import { Button } from "../../components/button";
import { Input } from "../../components/input";
import { Label } from "../../components/label";
import { Loading } from "../../components/loading";
import { Modal } from "../../components/modal";
import { NoAccess } from "../../components/no-access";
import { PageTemplateManagement } from "../../components/page-template-management";
import { Table } from "../../components/table";
import { actions } from "../../types/actions";
import { useAppData } from "../../hooks/use-app-data";
import { useFormInput } from "../../hooks/use-form-input";
import { modalCustomStyles } from "../../styles/creatable-select";
import { Option } from "../../types/reactSelectOption";
import { userHasAccess } from "../../util/check-user-access";
import { pages } from "../../util/pages";
import { Client, userRole } from "../../api/__generated__/types";
import {
  useDeleteClient,
  useGenerateClientUrl,
  useGetClientsManagement,
} from "../../api/__generated__/hooks/";
import { useLocationsManagement } from "../../hooks/use-locations-management";

interface ModalClient {
  sessionId?: string;
  name: string;
  location: string;
}

interface Location {
  value: string;
  label: string;
}

export const DeviceManagement = () => {
  const { state, dispatch } = useAppData();
  const [showModalCreateClient, setShowModalCreateClient] = useState(false);
  const [showModalRevokeClient, setShowModalRevokeClient] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  );
  const [modalClient, setModalClient] = useState<ModalClient | null>(null);
  const inputName = useFormInput("");
  const [isLoading, setIsLoading] = useState(true);
  const { apiClientLocations } = useLocationsManagement();
  const { data: apiUsers, error: usersError } =
    useGetClientsManagement(undefined);
  const sessionId = modalClient?.sessionId ?? "";

  const [showClientNameRequiredError, setShowClientNameRequiredError] =
    useState(false);
  const [showClientNameError, setShowClientNameError] = useState(false);
  const [showLocationRequiredError, setShowLocationRequiredError] =
    useState(false);
  useMsalAuthentication(InteractionType.Redirect);
  const navigate = useNavigate();

  const mappedLocations = state.locations.map((location) => ({
    label: location.replace(/_/g, " ").toLowerCase(),
    value: location,
  }));

  useEffect(() => {
    if (userHasAccess(state.profile, [userRole.ADMIN])) {
      if (apiUsers) {
        dispatch({ type: actions.SET_CLIENTS, payload: apiUsers });
      }

      if (apiUsers && apiClientLocations) {
        setIsLoading(false);
      }

      if (usersError) {
        console.error("users could not be retrieved", usersError);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message: `Current users could not be retrieved.`,
          },
        });
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [apiUsers, usersError, dispatch, apiClientLocations, state.profile]);

  const openModalCreateClient = () => {
    setShowModalCreateClient(true);
  };

  const onCloseModalCreateClient = useCallback(() => {
    setShowModalCreateClient(false);
  }, []);

  const openModalRevokeClient = (
    client: React.SetStateAction<ModalClient | null>
  ) => {
    setShowModalRevokeClient(true);
    setModalClient(client);
  };

  const onCloseModalRevokeClient = () => {
    setShowModalRevokeClient(false);
  };

  const handleSelectedLocation = (newValue: SingleValue<Option>) => {
    const location = newValue;
    setSelectedLocation(location);
  };

  const { mutate: generateClientUrl } = useGenerateClientUrl({
    mutation: {
      onSuccess: (qrData) => {
        if (!state.clients || !selectedLocation) {
          throw Error("Clients is null");
        }

        const createdClient: Client = {
          name: inputName.input.value,
          location: selectedLocation.value,
        };
        // Update newly created client with id
        createdClient.sessionId = qrData.sessionId;
        state.clients.push(createdClient);

        dispatch({
          type: actions.SET_CLIENTS,
          payload: state.clients,
        });

        // Set qr data to be used for rendering qr code
        dispatch({
          type: actions.SET_QR_DATA,
          payload: JSON.stringify(qrData),
        });

        // Redirect to qr code page
        navigate(pages.MANAGEMENT_DEVICES_REGISTER);
      },
      onError: (error) => {
        console.error("QR code could not be generated", error);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message: `QR code could not be generated, please try again.`,
          },
        });
      },
      onSettled: () => {
        onCloseModalCreateClient();
      },
    },
  });

  const submitCreateClient = () => {
    setShowClientNameRequiredError(false);
    setShowClientNameError(false);
    setShowLocationRequiredError(false);

    if (!selectedLocation) {
      throw Error("selected location is null");
    }
    const createdClient: Client = {
      name: inputName.input.value,
      location: selectedLocation.value,
    };

    if (!inputName.input.value) {
      setShowClientNameRequiredError(true);
      return;
    }
    // Check for min 4 max 100 characters
    const trimmedInputClientName = inputName.input.value.trim();
    if (!/^.{0,100}$/.test(trimmedInputClientName)) {
      setShowClientNameError(true);
      return;
    }

    if (!selectedLocation || !selectedLocation.value) {
      setShowLocationRequiredError(true);
      return;
    }

    generateClientUrl(createdClient);
  };

  const deleteClientMutation = useDeleteClient(sessionId, {
    mutation: {
      onSuccess: () => {
        setShowModalRevokeClient(false);
        if (!state.clients) {
          throw Error("clients is null or undefined");
        }
        if (modalClient) {
          const indexClient = state.clients.indexOf(modalClient);
          if (indexClient !== -1) {
            state.clients.splice(indexClient, 1);
            dispatch({
              type: actions.SET_CLIENTS,
              payload: state.clients,
            });
          }
        }
      },
      onError: (error) => {
        console.error("client could not be deleted", error);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message: `Client could not be deleted.`,
          },
        });
      },
    },
  });

  const submitRevokeClient = async () => {
    if (!modalClient || !modalClient.sessionId) {
      return;
    }

    deleteClientMutation.mutate(sessionId);
  };

  if (!userHasAccess(state.profile, [userRole.ADMIN])) {
    return state.profile ? <NoAccess /> : <Loading />;
  }

  return (
    <PageTemplateManagement error={state.error}>
      <div className={"flex flex-row items-center mb-6"}>
        <h2 className={"text-3xl"}>Manage your devices</h2>

        <Button
          variant={"primary"}
          onPointerDown={() => openModalCreateClient()}
          className={"ml-8 py-2 w-48 text-lg"}
        >
          Register device
        </Button>
      </div>

      {isLoading && <div className="ml-6">Loading...</div>}
      {state.clients && state.clients.length === 0 && !isLoading && (
        <div>No devices found</div>
      )}

      {state.clients && state.clients.length > 0 && !isLoading && (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Location</th>
              <th>Last Used</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {state.clients &&
              state.clients.length > 0 &&
              state.clients.map((client, i) => (
                <tr key={i}>
                  <td>{client.name}</td>
                  <td className="capitalize">
                    {client.location.replace(/_/g, " ").toLowerCase()}
                  </td>
                  <td>
                    {client.lastUsed
                      ? new Intl.DateTimeFormat(undefined, {
                          dateStyle: "long",
                          timeStyle: "short",
                        }).format(new Date(client.lastUsed))
                      : "Date not available"}
                  </td>
                  <td>
                    <Button
                      variant={"link"}
                      onPointerDown={() => openModalRevokeClient(client)}
                      className={"text-red hover:text-red-dark"}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}

      <Modal
        className={"w-1/2 flex flex-col gap-6"}
        onClose={onCloseModalCreateClient}
        show={showModalCreateClient}
      >
        <h2 className={"mb-0"}>Register new device</h2>
        <form>
          <Label htmlFor="device">Name</Label>
          <Input
            id="device"
            name="device"
            type="text"
            required
            {...inputName.input}
            placeholder="Name your device"
            className={
              "w-full bg-white rounded-lg border-2 border-black/10 text-base focus:bg-white"
            }
          />
          {showClientNameRequiredError && (
            <div className={"input-error"}>Device name is required</div>
          )}
          {showClientNameError && (
            <div className={"input-error"}>
              The device name can not be longer than 100 characters in length.
            </div>
          )}
          <div className={"mt-6"}>
            <Label htmlFor="location">Location</Label>
            {state.locations && (
              <Select
                name="location"
                placeholder="Select location"
                options={mappedLocations}
                styles={modalCustomStyles}
                getOptionLabel={(option: Option) => option.label}
                onChange={handleSelectedLocation}
              />
            )}
          </div>
          {showLocationRequiredError && (
            <div className={"input-error"}>Location is required</div>
          )}
        </form>
        <div className="flex justify-center gap-6">
          <Button
            variant={"secondary"}
            onPointerDown={() => onCloseModalCreateClient()}
            className={"py-3"}
          >
            Cancel
          </Button>
          <Button
            variant={"primary"}
            onPointerDown={() => submitCreateClient()}
            className={"py-3"}
          >
            Register
          </Button>
        </div>
      </Modal>

      <Modal
        className={"w-1/2 flex flex-col gap-6"}
        onClose={onCloseModalRevokeClient}
        show={showModalRevokeClient}
      >
        <h2>Delete device</h2>
        <div>
          <p>
            Are you sure you want to delete device{" "}
            {modalClient ? (
              <span className="client-name">{modalClient.name}</span>
            ) : (
              <span className="client-name">No Client selected </span>
            )}
          </p>
          <p>
            To activate this device again, it will have to be recreated and
            activated again by scanning the QR code.
          </p>
        </div>
        <div className="flex justify-center gap-6">
          <Button
            variant={"secondary"}
            onPointerDown={() => onCloseModalRevokeClient()}
            className={"py-3"}
          >
            Cancel
          </Button>
          <Button
            variant={"primary"}
            onPointerDown={() => submitRevokeClient()}
            className={"py-3"}
          >
            Delete device
          </Button>
        </div>
      </Modal>
    </PageTemplateManagement>
  );
};
