import React, { useEffect } from "react";
import { Button } from "../../components/button";
import { PageTemplateClient } from "../../components/page-template-client";
import { actions } from "../../types/actions";
import { useAppData } from "../../hooks/use-app-data";

export const QrCodeScanSuccess = () => {
  const { dispatch } = useAppData();

  useEffect(() => {
    dispatch({
      type: actions.SET_ERROR,
      payload: null,
    });
  }, [dispatch]);

  const backToStartHandler = (e: React.PointerEvent) => {
    if (e) e.preventDefault();
    // We do a browser navigation (with refresh) to be sure the token is loaded from local storage successfully
    window.location.assign("/");
  };

  return (
    <PageTemplateClient>
      <h1>👍 All good!</h1>
      <p className={"mt-6"}>This device is successfully registered.</p>
      <div className={"flex justify-center"}>
        <Button
          variant={"primary"}
          onPointerDown={backToStartHandler}
          className={"mt-6"}
        >
          Done
        </Button>
      </div>
    </PageTemplateClient>
  );
};
