import client from "../../client";
import {
  useQuery,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import type { GetVatManagementClientsLocationsQueryResponse } from "../types/GetVatManagementClientsLocations";
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";

type GetVatManagementClientsLocationsClient = typeof client<
  GetVatManagementClientsLocationsQueryResponse,
  never,
  never
>;
type GetVatManagementClientsLocations = {
  data: GetVatManagementClientsLocationsQueryResponse;
  error: never;
  request: never;
  pathParams: never;
  queryParams: never;
  headerParams: never;
  response: GetVatManagementClientsLocationsQueryResponse;
  client: {
    parameters: Partial<Parameters<GetVatManagementClientsLocationsClient>[0]>;
    return: Awaited<ReturnType<GetVatManagementClientsLocationsClient>>;
  };
};
export const getVatManagementClientsLocationsQueryKey = () =>
  [{ url: "/vat-management/clients/locations" }] as const;
export type GetVatManagementClientsLocationsQueryKey = ReturnType<
  typeof getVatManagementClientsLocationsQueryKey
>;
export function getVatManagementClientsLocationsQueryOptions(
  options: GetVatManagementClientsLocations["client"]["parameters"] = {}
) {
  const queryKey = getVatManagementClientsLocationsQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetVatManagementClientsLocations["data"],
        GetVatManagementClientsLocations["error"]
      >({
        method: "get",
        url: `/vat-management/clients/locations`,
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @summary Retrieves the location of all campuses
 * @link /vat-management/clients/locations
 */
export function useGetVatManagementClientsLocations<
  TData = GetVatManagementClientsLocations["response"],
  TQueryData = GetVatManagementClientsLocations["response"],
  TQueryKey extends QueryKey = GetVatManagementClientsLocationsQueryKey
>(
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetVatManagementClientsLocations["response"],
        GetVatManagementClientsLocations["error"],
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: GetVatManagementClientsLocations["client"]["parameters"];
  } = {}
): UseQueryResult<TData, GetVatManagementClientsLocations["error"]> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getVatManagementClientsLocationsQueryKey();
  const query = useQuery({
    ...(getVatManagementClientsLocationsQueryOptions(
      clientOptions
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">),
  }) as UseQueryResult<TData, GetVatManagementClientsLocations["error"]> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
export const getVatManagementClientsLocationsSuspenseQueryKey = () =>
  [{ url: "/vat-management/clients/locations" }] as const;
export type GetVatManagementClientsLocationsSuspenseQueryKey = ReturnType<
  typeof getVatManagementClientsLocationsSuspenseQueryKey
>;
export function getVatManagementClientsLocationsSuspenseQueryOptions(
  options: GetVatManagementClientsLocations["client"]["parameters"] = {}
) {
  const queryKey = getVatManagementClientsLocationsSuspenseQueryKey();
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetVatManagementClientsLocations["data"],
        GetVatManagementClientsLocations["error"]
      >({
        method: "get",
        url: `/vat-management/clients/locations`,
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @summary Retrieves the location of all campuses
 * @link /vat-management/clients/locations
 */
export function useGetVatManagementClientsLocationsSuspense<
  TData = GetVatManagementClientsLocations["response"],
  TQueryKey extends QueryKey = GetVatManagementClientsLocationsSuspenseQueryKey
>(
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetVatManagementClientsLocations["response"],
        GetVatManagementClientsLocations["error"],
        TData,
        TQueryKey
      >
    >;
    client?: GetVatManagementClientsLocations["client"]["parameters"];
  } = {}
): UseSuspenseQueryResult<TData, GetVatManagementClientsLocations["error"]> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ??
    getVatManagementClientsLocationsSuspenseQueryKey();
  const query = useSuspenseQuery({
    ...(getVatManagementClientsLocationsSuspenseQueryOptions(
      clientOptions
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">),
  }) as UseSuspenseQueryResult<
    TData,
    GetVatManagementClientsLocations["error"]
  > & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
