import React from "react";
import { twMerge } from "tailwind-merge";

type BadgeProps = {
  children: React.ReactNode;
  variant: "error" | "success";
  className?: string;
};

export const Badge = ({
  children,
  variant,
  className,
  ...props
}: BadgeProps) => {
  switch (variant) {
    case "success":
      return (
        <div
          className={twMerge(
            "px-2 py-1 rounded-md text-center bg-green-light text-green-dark",
            className
          )}
          {...props}
        >
          {children}
        </div>
      );
    case "error":
      return (
        <div
          className={twMerge(
            "px-2 py-1 rounded-md text-center bg-red-light text-red-dark",
            className
          )}
          {...props}
        >
          {children}
        </div>
      );
    default:
      throw new Error("Variant prop for Badge is required.");
  }
};
