import client from "../../client";
import { useMutation } from "@tanstack/react-query";
import type {
  UpdateRoleOfUserMutationRequest,
  UpdateRoleOfUserMutationResponse,
  UpdateRoleOfUserPathParams,
} from "../types/UpdateRoleOfUser";
import type { UseMutationOptions } from "@tanstack/react-query";

type UpdateRoleOfUserClient = typeof client<
  UpdateRoleOfUserMutationResponse,
  never,
  UpdateRoleOfUserMutationRequest
>;
type UpdateRoleOfUser = {
  data: UpdateRoleOfUserMutationResponse;
  error: never;
  request: UpdateRoleOfUserMutationRequest;
  pathParams: UpdateRoleOfUserPathParams;
  queryParams: never;
  headerParams: never;
  response: UpdateRoleOfUserMutationResponse;
  client: {
    parameters: Partial<Parameters<UpdateRoleOfUserClient>[0]>;
    return: Awaited<ReturnType<UpdateRoleOfUserClient>>;
  };
};
/**
 * @description Update the role of user with userId
 * @link /vat-management/users/:userId/update-role
 */
export function useUpdateRoleOfUser(
  userId: UpdateRoleOfUserPathParams["userId"],
  options: {
    mutation?: UseMutationOptions<
      UpdateRoleOfUser["response"],
      UpdateRoleOfUser["error"],
      UpdateRoleOfUser["request"]
    >;
    client?: UpdateRoleOfUser["client"]["parameters"];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async (data) => {
      const res = await client<
        UpdateRoleOfUser["data"],
        UpdateRoleOfUser["error"],
        UpdateRoleOfUser["request"]
      >({
        method: "put",
        url: `/vat-management/users/${userId}/update-role`,
        data,
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}
