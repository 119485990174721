import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Button } from "../components/button";
import { CurrentRegisteredDevice } from "../components/current-registered-device";
import { PageTemplateClient } from "../components/page-template-client";
import { actions } from "../types/actions";
import { useAppData } from "../hooks/use-app-data";
import { pages } from "../util/pages";
import "../styles/index.css";
import { useRetrieveOrganizers } from "../api/__generated__/hooks/";

export const Home = () => {
  const { state, dispatch } = useAppData();
  const navigate = useNavigate();
  const { data: organizers, isError, error } = useRetrieveOrganizers();

  useEffect(() => {
    // We collect iO organizers here, so we can use it when the confirmation page is shown.
    if (organizers) {
      dispatch({
        type: actions.SET_IO_ORGANIZERS,
        payload: organizers,
      });
    } else if (isError) {
      console.error("Error fetching organizers", error);
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          code: "application_error",
          message: "Current organizers could not be retrieved",
        },
      });
    }
  }, [dispatch, error, isError, organizers]);

  useEffect(() => {
    // Clear captured image as a flow has been completed or this is first load
    if (state.capturedImage) {
      dispatch({
        type: actions.SET_CAPTURED_IMAGE,
        payload: null,
      });
    }
    // Only trigger once on render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkInOutHandler = () => {
    if (!navigator.mediaDevices) {
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          code: "application_error",
          message: "Face recognition requires https",
        },
      });
      return;
    }
    dispatch({
      type: actions.SET_ERROR,
      payload: null,
    });
    navigate(pages.RECOGNIZE);
  };

  const firstTimeHandler = (e: React.PointerEvent) => {
    e.preventDefault();
    dispatch({
      type: actions.SET_ERROR,
      payload: null,
    });
    navigate({ pathname: pages.RECOGNIZE, search: "?manual=true" });
  };

  const privacyPolicyHandler = (e: React.PointerEvent) => {
    e.preventDefault();
    dispatch({
      type: actions.SET_ERROR,
      payload: null,
    });
    navigate(pages.PRIVACY_POLICY);
  };

  const HomeFooter = () => (
    <>
      <p>
        By tapping <i>Check in / out</i> or <i>First time here?</i> <br />
        you agree to our{" "}
        <Button variant={"link"} onPointerDown={privacyPolicyHandler}>
          privacy&nbsp;policy
        </Button>
      </p>
      <CurrentRegisteredDevice />
    </>
  );

  return (
    <PageTemplateClient footer={<HomeFooter />}>
      <h1 className={"text-h1 text-grey-grey90"}>Hello there!</h1>
      <h1 className={"mb-32 text-h1 text-grey-grey90"}>Welcome to iO</h1>

      <div className={"flex w-[55vw] items-start flex-col gap-8"}>
        <Button
          variant={"primary"}
          onPointerDown={checkInOutHandler}
          data-testid={"btn-face-recognition"}
        >
          Check in / out
        </Button>
        <Button
          variant={"secondary"}
          onPointerDown={firstTimeHandler}
          data-testid={"btn-manual-form"}
        >
          First time here?
        </Button>
      </div>
    </PageTemplateClient>
  );
};
