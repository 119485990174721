import client from "../../client";
import {
  useQuery,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import type {
  GetVisitorsClientQueryResponse,
  GetVisitorsClientQueryParams,
} from "../types/GetVisitorsClient";
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";

type GetVisitorsClientClient = typeof client<
  GetVisitorsClientQueryResponse,
  never,
  never
>;
type GetVisitorsClient = {
  data: GetVisitorsClientQueryResponse;
  error: never;
  request: never;
  pathParams: never;
  queryParams: GetVisitorsClientQueryParams;
  headerParams: never;
  response: GetVisitorsClientQueryResponse;
  client: {
    parameters: Partial<Parameters<GetVisitorsClientClient>[0]>;
    return: Awaited<ReturnType<GetVisitorsClientClient>>;
  };
};
export const getVisitorsClientQueryKey = (
  params: GetVisitorsClient["queryParams"]
) => [{ url: "/vat-client/visitors" }, ...(params ? [params] : [])] as const;
export type GetVisitorsClientQueryKey = ReturnType<
  typeof getVisitorsClientQueryKey
>;
export function getVisitorsClientQueryOptions(
  params: GetVisitorsClient["queryParams"],
  options: GetVisitorsClient["client"]["parameters"] = {}
) {
  const queryKey = getVisitorsClientQueryKey(params);
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetVisitorsClient["data"],
        GetVisitorsClient["error"]
      >({
        method: "get",
        url: `/vat-client/visitors`,
        params,
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @description Get a list of visitors
 * @link /vat-client/visitors
 */
export function useGetVisitorsClient<
  TData = GetVisitorsClient["response"],
  TQueryData = GetVisitorsClient["response"],
  TQueryKey extends QueryKey = GetVisitorsClientQueryKey
>(
  params: GetVisitorsClient["queryParams"],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetVisitorsClient["response"],
        GetVisitorsClient["error"],
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: GetVisitorsClient["client"]["parameters"];
  } = {}
): UseQueryResult<TData, GetVisitorsClient["error"]> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getVisitorsClientQueryKey(params);
  const query = useQuery({
    ...(getVisitorsClientQueryOptions(
      params,
      clientOptions
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">),
  }) as UseQueryResult<TData, GetVisitorsClient["error"]> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
export const getVisitorsClientSuspenseQueryKey = (
  params: GetVisitorsClient["queryParams"]
) => [{ url: "/vat-client/visitors" }, ...(params ? [params] : [])] as const;
export type GetVisitorsClientSuspenseQueryKey = ReturnType<
  typeof getVisitorsClientSuspenseQueryKey
>;
export function getVisitorsClientSuspenseQueryOptions(
  params: GetVisitorsClient["queryParams"],
  options: GetVisitorsClient["client"]["parameters"] = {}
) {
  const queryKey = getVisitorsClientSuspenseQueryKey(params);
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetVisitorsClient["data"],
        GetVisitorsClient["error"]
      >({
        method: "get",
        url: `/vat-client/visitors`,
        params,
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @description Get a list of visitors
 * @link /vat-client/visitors
 */
export function useGetVisitorsClientSuspense<
  TData = GetVisitorsClient["response"],
  TQueryKey extends QueryKey = GetVisitorsClientSuspenseQueryKey
>(
  params: GetVisitorsClient["queryParams"],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetVisitorsClient["response"],
        GetVisitorsClient["error"],
        TData,
        TQueryKey
      >
    >;
    client?: GetVisitorsClient["client"]["parameters"];
  } = {}
): UseSuspenseQueryResult<TData, GetVisitorsClient["error"]> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getVisitorsClientSuspenseQueryKey(params);
  const query = useSuspenseQuery({
    ...(getVisitorsClientSuspenseQueryOptions(
      params,
      clientOptions
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">),
  }) as UseSuspenseQueryResult<TData, GetVisitorsClient["error"]> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
