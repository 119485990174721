import { twMerge } from "tailwind-merge";
import { useCountdown } from "../hooks/use-countdown";

type ProgressBarProps = {
  className?: string;
  durationSeconds?: number;
  onFinished: () => void;
};

export const ProgressBar = ({
  className,
  durationSeconds = 30,
  onFinished,
}: ProgressBarProps) => {
  const seconds = useCountdown({
    onFinished,
    initialTicks: durationSeconds,
    tickDuration: 1000,
  });

  return (
    <div
      className={twMerge(
        "fixed bottom-0 left-0 h-1 bg-black transition-width duration-1000 ease-linear",
        className
      )}
      style={{ width: `${(100 / durationSeconds) * seconds}%` }}
    >
      &nbsp;
    </div>
  );
};
