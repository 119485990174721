import React from "react";
import { twMerge } from "tailwind-merge";

type DropdownProps = {
  className?: string;
  options: { value: string; label: string }[];
  defaultValue: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const Dropdown = ({
  className,
  options,
  defaultValue,
  onChange,
  ...props
}: DropdownProps) => {
  return (
    <select
      value={defaultValue}
      onChange={onChange}
      className={twMerge(
        "bg-primary-blue p-2.5 w-72 text-white text-2xl text-center rounded-full overflow-hidden capitalize appearance-none cursor-pointer",
        className
      )}
      {...props}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
