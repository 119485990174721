import client from "../../client";
import { useMutation } from "@tanstack/react-query";
import type {
  ManualVisitorHandlingMutationRequest,
  ManualVisitorHandlingMutationResponse,
} from "../types/ManualVisitorHandling";
import type { UseMutationOptions } from "@tanstack/react-query";

type ManualVisitorHandlingClient = typeof client<
  ManualVisitorHandlingMutationResponse,
  never,
  ManualVisitorHandlingMutationRequest
>;
type ManualVisitorHandling = {
  data: ManualVisitorHandlingMutationResponse;
  error: never;
  request: ManualVisitorHandlingMutationRequest;
  pathParams: never;
  queryParams: never;
  headerParams: never;
  response: ManualVisitorHandlingMutationResponse;
  client: {
    parameters: Partial<Parameters<ManualVisitorHandlingClient>[0]>;
    return: Awaited<ReturnType<ManualVisitorHandlingClient>>;
  };
};
/**
 * @description Handles a visitor reporting itself manually
 * @link /vat-client/visitors/manual
 */
export function useManualVisitorHandling(
  options: {
    mutation?: UseMutationOptions<
      ManualVisitorHandling["response"],
      ManualVisitorHandling["error"],
      ManualVisitorHandling["request"]
    >;
    client?: ManualVisitorHandling["client"]["parameters"];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async (data) => {
      const res = await client<
        ManualVisitorHandling["data"],
        ManualVisitorHandling["error"],
        ManualVisitorHandling["request"]
      >({
        method: "post",
        url: `/vat-client/visitors/manual`,
        data,
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}
