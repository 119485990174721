import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Badge } from "../../components/badge";
import { Button } from "../../components/button";
import { Dropdown } from "../../components/dropdown";
import { Loading } from "../../components/loading";
import { NoAccess } from "../../components/no-access";
import { PageTemplateManagement } from "../../components/page-template-management";
import { Table } from "../../components/table";
import { actions } from "../../types/actions";
import { useAppData } from "../../hooks/use-app-data";
import { addLeadingZero } from "../../util/add-leading-zero";
import { userHasAccess } from "../../util/check-user-access";
import { Visit, Visitor, userRole } from "../../api/__generated__/types";
import {
  useGetVisitorsManagement,
  useVisitorCheckOutByAdmin,
} from "../../api/__generated__/hooks/";
import { useLocationsManagement } from "../../hooks/use-locations-management";
import { useQueryClient } from "@tanstack/react-query";

export const VisitorManagement = () => {
  const { state, dispatch } = useAppData();
  const [isLoading, setIsLoading] = useState(true);
  const today = new Date();
  const convertDateTimeToDateString = (dateTime: Date) =>
    `${dateTime.getFullYear()}-${addLeadingZero(
      dateTime.getMonth() + 1
    )}-${addLeadingZero(dateTime.getDate())}`;
  const todayFullString = convertDateTimeToDateString(today);
  const [dayFullString, setDayFullString] = useState(todayFullString);
  const { inProgress } = useMsal();
  useMsalAuthentication(InteractionType.Redirect);
  const [datePickerStartDate, setDatePickerStartDate] = useState(new Date());
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [visitors, setVisitors] = useState<Visitor[]>([]);
  const [visit, setVisit] = useState<string | null>(null);
  const queryClient = useQueryClient();

  // Only retrieve the locations
  useLocationsManagement();

  const {
    data: visitorsData,
    isLoading: loading,
    error: visitorsError,
  } = useGetVisitorsManagement(
    { "day-start": dayFullString, "day-end": dayFullString },
    {
      query: {
        enabled: !!dayFullString,
      },
    }
  );

  const { mutateAsync: checkOut, error: checkoutError } =
    useVisitorCheckOutByAdmin(visit ?? "");

  useEffect(() => {
    if (checkoutError) {
      console.error(checkoutError);
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          code: "application_error",
          message: "Visitor could not be checked out.",
        },
      });
    }
  }, [checkoutError, dispatch]);

  useEffect(() => {
    if (
      state.profile &&
      (state.profile.role === userRole.VISITOR_MANAGEMENT ||
        state.profile.role === userRole.ADMIN)
    ) {
      setIsLoading(loading);

      if (visitorsData) {
        setVisitors(visitorsData);
      }
      if (visitorsError) {
        console.error(visitorsError);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message:
              "Current visitors could not be retrieved, please try again later.",
          },
        });
      }
    }
  }, [dispatch, loading, state.profile, visitorsData, visitorsError]);

  const [locationFilter, setLocationFilter] = useState(
    localStorage.getItem("location") || ""
  );

  const isCheckedIn = (visit: Visit) =>
    visit && visit.checkInTime && !visit.checkOutTime;

  const isAnyVisitorCheckedIn = (visitors: Visitor[]) =>
    visitors.some((visitor) => visitor.visits.some(isCheckedIn));

  const shouldShowActionColumn =
    userHasAccess(state.profile, [userRole.ADMIN]) &&
    isAnyVisitorCheckedIn(visitors);

  const dateTimeHourMinutes = (dateTime: string | undefined) => {
    if (dateTime) {
      const dateObject = new Date(dateTime);
      return `${addLeadingZero(dateObject.getHours())}:${addLeadingZero(
        dateObject.getMinutes()
      )}`;
    }
    return "";
  };

  const handleVisitorSearch = async (date: Date | null) => {
    setDatePickerIsOpen(false);
    setDatePickerStartDate((prevStartDate) => (date ? date : prevStartDate));
    const dateFullString = date ? convertDateTimeToDateString(date) : "";
    setDayFullString(dateFullString);
    setIsLoading(false);
  };

  const handleOpenDatePicker = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setDatePickerIsOpen(!datePickerIsOpen);
  };

  const handleCheckOut = async (visitor: Visitor) => {
    setIsLoading(true);
    setVisit(visitor.id);
    await checkOut({ visitorId: visitor.id });

    queryClient.invalidateQueries({
      queryKey: [
        { url: "/vat-management/visitors" },
        { "day-start": dayFullString, "day-end": dayFullString },
      ],
    });
    setIsLoading(false);
  };

  const selectedDate = `${datePickerStartDate.toLocaleString("en-GB", {
    month: "long",
  })}
  ${datePickerStartDate.getDate()}`;

  // Sets the standard input field as a button, as we don't want the text field.
  const CustomInputField = forwardRef<HTMLButtonElement, unknown>(
    (props, ref) => (
      <Button
        variant="primary"
        onPointerDown={handleOpenDatePicker}
        ref={ref}
        className="py-2 px-2 w-72"
      >
        {selectedDate}
      </Button>
    )
  );

  CustomInputField.displayName = "CustomInputField";

  const getVisitorStatus = (visits: Visit) =>
    isCheckedIn(visits) ? (
      <Badge variant="success">Checked in</Badge>
    ) : (
      <Badge variant="error">Checked out</Badge>
    );

  const handleSelectedLocation = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLocationFilter(event.target.value);
    localStorage.setItem("location", event.target.value);
  };

  useEffect(() => {
    if (inProgress === InteractionStatus.None && visitorsData) {
      setVisitors(visitorsData);
    }
  }, [inProgress, visitorsData]);

  if (
    !userHasAccess(state.profile, [userRole.VISITOR_MANAGEMENT, userRole.ADMIN])
  ) {
    return state.profile ? <NoAccess /> : <Loading />;
  }

  const filteredVisitors = visitors.filter((visitor) =>
    visitor.visits.some((visit) => visit.location === locationFilter)
  );

  return (
    <PageTemplateManagement error={state.error}>
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
        <div className="mb-4 md:mb-0 md:mr-4 ">
          <DatePicker
            selected={datePickerStartDate}
            onChange={(date: Date | null) => handleVisitorSearch(date)}
            open={datePickerIsOpen}
            customInput={<CustomInputField />}
            showPopperArrow={false}
            onClickOutside={handleOpenDatePicker}
            maxDate={today}
          />
        </div>
        <div className="w-full md:w-auto">
          {state.locations && (
            <Dropdown
              options={[
                {
                  label: "Select Campus",
                  value: "",
                },
              ].concat(
                state.locations.map((location) => ({
                  value: location,
                  label: location.replace(/_/g, " ").toLowerCase(),
                }))
              )}
              onChange={handleSelectedLocation}
              defaultValue={locationFilter}
            />
          )}
        </div>
      </div>
      {isLoading && <p className="text-xl mt-6">Loading...</p>}

      {filteredVisitors.length > 0 && locationFilter && (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>E-mail</th>
              <th>Organization</th>
              <th>Appointment with</th>
              <th>Location</th>
              <th>Status</th>
              <th>Time</th>
              {shouldShowActionColumn && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {visitors.map((visitor) =>
              visitor.visits
                .filter((visit) => visit.location === locationFilter)
                .map((visit, indexVisit) => (
                  <tr key={indexVisit}>
                    <td>{visitor.name}</td>
                    <td>{visitor.email}</td>
                    <td>{visitor.organization}</td>
                    <td>{visit.appointmentWith}</td>
                    <td className={"capitalize"}>
                      {visit.location
                        .replace(/_/g, " ")
                        .replace(/campus\s*/i, "")
                        .toLowerCase()}
                    </td>
                    <td>{getVisitorStatus(visit)}</td>
                    <td>
                      {dateTimeHourMinutes(visit.checkInTime)}
                      {visit.checkOutTime && (
                        <>{` - ${dateTimeHourMinutes(visit.checkOutTime)}`}</>
                      )}
                    </td>
                    {shouldShowActionColumn && (
                      <td>
                        {isCheckedIn(visit) && (
                          <Button
                            variant="primary"
                            className={"py-2 px-4 w-auto whitespace-nowrap"}
                            onPointerDown={() => handleCheckOut(visitor)}
                          >
                            Check out
                          </Button>
                        )}
                      </td>
                    )}
                  </tr>
                ))
            )}
          </tbody>
        </Table>
      )}

      {!locationFilter && <p className={"mt-6"}>Please select a campus</p>}

      {filteredVisitors.length === 0 && locationFilter && !isLoading && (
        <p className={"mt-6"}>No visitors found on {dayFullString}</p>
      )}
    </PageTemplateManagement>
  );
};
