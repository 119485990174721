import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import { getEnvironmentFromHost, isLocalhost } from "./check-environment";
import { useEffect } from "react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  Routes,
} from "react-router-dom";

const history = createBrowserHistory();
const environment = getEnvironmentFromHost();

let integrations = [
  Sentry.browserTracingIntegration({
    useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
    history,
    instrumentNavigation: true,
    instrumentPageLoad: true,
  }),
];

if (!isLocalhost()) {
  integrations.push(Sentry.replayIntegration());
}

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment,
    integrations,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/.*/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
