import { StylesConfig } from "react-select";
import { Option } from "../types/reactSelectOption";

const modalSelectColors = {
  default: "#FFFFFF",
  active: "#F1EFEE",
  grey: "#929292",
};

const formSelectColors = {
  default: "#DCE5E4",
  active: "#DCE5E4",
};

const defaultColors = {
  black: "#000000",
  white: "#ffffff",
  option: "#ffffff",
  calmGreen: "#C4D1CE",
  grey90: "#242424",
  grey70: "#747474",
  placeholder: "#2525254D",
};

const modalCustomStyles: StylesConfig<Option, false> = {
  placeholder: (provided) => ({
    ...provided,
    color: modalSelectColors.grey,
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: defaultColors.white,
    border: state.isFocused ? "1px solid black" : "1px solid #E8E8E8",
    borderRadius: 8,
    boxShadow: "inset 0 0 2px rgba(0,0,0,.2)",
    padding: "6px 0px 6px",
    ":hover": {
      cursor: "text",
    },
  }),
  input: (provided) => ({
    ...provided,
    color: defaultColors.black,
    fontSize: "15px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: defaultColors.black,
    fontSize: "15px",
    textTransform: "capitalize",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? modalSelectColors.active
      : defaultColors.white,
    color: defaultColors.black,
    ":hover": {
      cursor: "pointer",
      backgroundColor: modalSelectColors.active,
      color: defaultColors.black,
    },
    textTransform: "capitalize",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    display: "block",
    color: defaultColors.black,
    ":hover": {
      cursor: "pointer",
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: defaultColors.black,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: 200,
  }),
};

const defaultCustomStyles: StylesConfig<Option, false> = {
  placeholder: (provided) => ({
    ...provided,
    color: defaultColors.placeholder,
  }),
  control: (provided, state) => ({
    ...provided,
    border: "none",
    backgroundColor: "none",
    borderBottom: `1px solid ${defaultColors.grey90}`,
    borderRadius: "none",
    paddingBottom: "0.5rem",
    paddingTop: "0.5rem",
    outline: "none",
    borderBottomWidth: state.isFocused ? "2px" : "1px",
    borderBottomColor: state.isFocused
      ? defaultColors.grey90
      : defaultColors.grey90,
    boxShadow: "none",
  }),
  input: (provided) => ({
    ...provided,
    color: defaultColors.black,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: defaultColors.black,
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: defaultColors.white,
    color: defaultColors.black,
    ":hover": {
      cursor: "pointer",
      backgroundColor: formSelectColors.active,
      color: defaultColors.black,
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: defaultColors.black,
  }),
};

export { modalCustomStyles, defaultCustomStyles };
