import client from "../../client";
import { useMutation } from "@tanstack/react-query";
import type {
  DeleteClientMutationRequest,
  DeleteClientMutationResponse,
  DeleteClientPathParams,
} from "../types/DeleteClient";
import type { UseMutationOptions } from "@tanstack/react-query";

type DeleteClientClient = typeof client<
  DeleteClientMutationResponse,
  never,
  DeleteClientMutationRequest
>;
type DeleteClient = {
  data: DeleteClientMutationResponse;
  error: never;
  request: DeleteClientMutationRequest;
  pathParams: DeleteClientPathParams;
  queryParams: never;
  headerParams: never;
  response: DeleteClientMutationResponse;
  client: {
    parameters: Partial<Parameters<DeleteClientClient>[0]>;
    return: Awaited<ReturnType<DeleteClientClient>>;
  };
};
/**
 * @description Deletes a client by session id, therefore revoking the session
 * @link /vat-management/clients/:sessionId
 */
export function useDeleteClient(
  sessionId: DeleteClientPathParams["sessionId"],
  options: {
    mutation?: UseMutationOptions<
      DeleteClient["response"],
      DeleteClient["error"],
      DeleteClient["request"]
    >;
    client?: DeleteClient["client"]["parameters"];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async (data) => {
      const res = await client<
        DeleteClient["data"],
        DeleteClient["error"],
        DeleteClient["request"]
      >({
        method: "delete",
        url: `/vat-management/clients/${sessionId}`,
        data,
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}
