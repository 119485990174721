import client from "../../client";
import {
  useQuery,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import type {
  GetVisitorsManagementQueryResponse,
  GetVisitorsManagementQueryParams,
} from "../types/GetVisitorsManagement";
import type {
  QueryObserverOptions,
  UseQueryResult,
  QueryKey,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";

type GetVisitorsManagementClient = typeof client<
  GetVisitorsManagementQueryResponse,
  never,
  never
>;
type GetVisitorsManagement = {
  data: GetVisitorsManagementQueryResponse;
  error: never;
  request: never;
  pathParams: never;
  queryParams: GetVisitorsManagementQueryParams;
  headerParams: never;
  response: GetVisitorsManagementQueryResponse;
  client: {
    parameters: Partial<Parameters<GetVisitorsManagementClient>[0]>;
    return: Awaited<ReturnType<GetVisitorsManagementClient>>;
  };
};
export const getVisitorsManagementQueryKey = (
  params: GetVisitorsManagement["queryParams"]
) =>
  [{ url: "/vat-management/visitors" }, ...(params ? [params] : [])] as const;
export type GetVisitorsManagementQueryKey = ReturnType<
  typeof getVisitorsManagementQueryKey
>;
export function getVisitorsManagementQueryOptions(
  params: GetVisitorsManagement["queryParams"],
  options: GetVisitorsManagement["client"]["parameters"] = {}
) {
  const queryKey = getVisitorsManagementQueryKey(params);
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetVisitorsManagement["data"],
        GetVisitorsManagement["error"]
      >({
        method: "get",
        url: `/vat-management/visitors`,
        params,
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @description Get a list of visitors
 * @link /vat-management/visitors
 */
export function useGetVisitorsManagement<
  TData = GetVisitorsManagement["response"],
  TQueryData = GetVisitorsManagement["response"],
  TQueryKey extends QueryKey = GetVisitorsManagementQueryKey
>(
  params: GetVisitorsManagement["queryParams"],
  options: {
    query?: Partial<
      QueryObserverOptions<
        GetVisitorsManagement["response"],
        GetVisitorsManagement["error"],
        TData,
        TQueryData,
        TQueryKey
      >
    >;
    client?: GetVisitorsManagement["client"]["parameters"];
  } = {}
): UseQueryResult<TData, GetVisitorsManagement["error"]> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getVisitorsManagementQueryKey(params);
  const query = useQuery({
    ...(getVisitorsManagementQueryOptions(
      params,
      clientOptions
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">),
  }) as UseQueryResult<TData, GetVisitorsManagement["error"]> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
export const getVisitorsManagementSuspenseQueryKey = (
  params: GetVisitorsManagement["queryParams"]
) =>
  [{ url: "/vat-management/visitors" }, ...(params ? [params] : [])] as const;
export type GetVisitorsManagementSuspenseQueryKey = ReturnType<
  typeof getVisitorsManagementSuspenseQueryKey
>;
export function getVisitorsManagementSuspenseQueryOptions(
  params: GetVisitorsManagement["queryParams"],
  options: GetVisitorsManagement["client"]["parameters"] = {}
) {
  const queryKey = getVisitorsManagementSuspenseQueryKey(params);
  return queryOptions({
    queryKey,
    queryFn: async () => {
      const res = await client<
        GetVisitorsManagement["data"],
        GetVisitorsManagement["error"]
      >({
        method: "get",
        url: `/vat-management/visitors`,
        params,
        ...options,
      });
      return res.data;
    },
  });
}
/**
 * @description Get a list of visitors
 * @link /vat-management/visitors
 */
export function useGetVisitorsManagementSuspense<
  TData = GetVisitorsManagement["response"],
  TQueryKey extends QueryKey = GetVisitorsManagementSuspenseQueryKey
>(
  params: GetVisitorsManagement["queryParams"],
  options: {
    query?: Partial<
      UseSuspenseQueryOptions<
        GetVisitorsManagement["response"],
        GetVisitorsManagement["error"],
        TData,
        TQueryKey
      >
    >;
    client?: GetVisitorsManagement["client"]["parameters"];
  } = {}
): UseSuspenseQueryResult<TData, GetVisitorsManagement["error"]> & {
  queryKey: TQueryKey;
} {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey =
    queryOptions?.queryKey ?? getVisitorsManagementSuspenseQueryKey(params);
  const query = useSuspenseQuery({
    ...(getVisitorsManagementSuspenseQueryOptions(
      params,
      clientOptions
    ) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">),
  }) as UseSuspenseQueryResult<TData, GetVisitorsManagement["error"]> & {
    queryKey: TQueryKey;
  };
  query.queryKey = queryKey as TQueryKey;
  return query;
}
