import { useEffect } from "react";
import { useGetVatManagementClientsLocations } from "../api/__generated__/hooks";
import { actions } from "../types/actions";
import { useAppData } from "./use-app-data";
import { userRole } from "../api/__generated__/types/";

export const useLocationsManagement = () => {
  const { state, dispatch } = useAppData();
  const { data: apiClientLocations, error: locationsError } =
    useGetVatManagementClientsLocations();

  useEffect(() => {
    if (
      state.profile &&
      (state.profile.role === userRole.VISITOR_MANAGEMENT ||
        state.profile.role === userRole.ADMIN)
    ) {
      if (apiClientLocations) {
        dispatch({ type: actions.SET_LOCATIONS, payload: apiClientLocations });
      }

      if (locationsError) {
        console.error(locationsError);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message: "Could not retrieve locations.",
          },
        });
      }
    }
  }, [apiClientLocations, locationsError, dispatch, state.profile]);

  return { apiClientLocations };
};
