const ENVIRONMENTS = {
  UAT: "uat",
  LOCALHOST: "localhost",
  PRODUCTION: "production",
};

const browserHostname = window.location.hostname;

export const isLocalhost = () => {
  return browserHostname === "localhost";
};

const isUAT = () => {
  return browserHostname.includes("-uat");
};

export const getEnvironmentFromHost = () => {
  if (isUAT()) {
    return ENVIRONMENTS.UAT;
  } else if (isLocalhost()) {
    return ENVIRONMENTS.LOCALHOST;
  } else {
    return ENVIRONMENTS.PRODUCTION;
  }
};
