import client from "../../client";
import { useMutation } from "@tanstack/react-query";
import type {
  RecognizeFaceMutationRequest,
  RecognizeFaceMutationResponse,
  RecognizeFace404,
} from "../types/RecognizeFace";
import type { UseMutationOptions } from "@tanstack/react-query";

type RecognizeFaceClient = typeof client<
  RecognizeFaceMutationResponse,
  RecognizeFace404,
  RecognizeFaceMutationRequest
>;
type RecognizeFace = {
  data: RecognizeFaceMutationResponse;
  error: RecognizeFace404;
  request: RecognizeFaceMutationRequest;
  pathParams: never;
  queryParams: never;
  headerParams: never;
  response: RecognizeFaceMutationResponse;
  client: {
    parameters: Partial<Parameters<RecognizeFaceClient>[0]>;
    return: Awaited<ReturnType<RecognizeFaceClient>>;
  };
};
/**
 * @description Post an image to recognize
 * @link /vat-client/faces/recognize
 */
export function useRecognizeFace(
  options: {
    mutation?: UseMutationOptions<
      RecognizeFace["response"],
      RecognizeFace["error"],
      RecognizeFace["request"]
    >;
    client?: RecognizeFace["client"]["parameters"];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } =
    options ?? {};
  return useMutation({
    mutationFn: async (data) => {
      const res = await client<
        RecognizeFace["data"],
        RecognizeFace["error"],
        RecognizeFace["request"]
      >({
        method: "post",
        url: `/vat-client/faces/recognize`,
        data,
        ...clientOptions,
      });
      return res.data;
    },
    ...mutationOptions,
  });
}
